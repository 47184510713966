import { ProductType } from './product.model';
import { Deceased } from './order.model';

export interface DeceasedReport {
  status: ReportStatus;
  type:ProductType;
  reportNumber: string
  updatedAt: Date;
  createdAt:Date;
  databaseStatusOn: Date;
  deceased:Deceased;
  lookup: PrimaryReportLookup; //| ExtendedReportLookup
  result: PrimaryReportResult;// | ExtendedReportResult,

}


export enum ReportStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  SKIPPED = 'SKIPPED',
}
export interface  PrimaryReportLookup{
    krs:ReportLookupInfo;
    ceidg:ReportLookupInfo;
    crbr:ReportLookupInfo;
    krz:ReportLookupInfo;
    msig:ReportLookupInfo;
    vatWl:ReportLookupInfo;
    sudop:ReportLookupInfo;
}

export interface ReportLookupInfo {
  status: ReportStatus;
  timestamp: Date;
}

export interface  ExtendedReportLookup {
   //TBD
}

export interface PrimaryReportResult {
    krs: {
        propertyRights: PrimaryReportResultStatus;
        companyAuthorities: PrimaryReportResultStatus;
    },
    ceidg:PrimaryReportResultStatus;
    crbr:PrimaryReportResultStatus;
    krz:PrimaryReportResultStatus;
    msig:PrimaryReportResultStatus;
    vatWl:PrimaryReportResultStatus;
    sudop:PrimaryReportResultStatus;
}

export enum PrimaryReportResultStatus {
  NOT_EXISTS = 'NOT_EXISTS',
  EXISTS = 'EXISTS',
  SKIPPED = 'SKIPPED',
  PENDING = 'PENDING',
}

export interface ExtendedReportResult {
  //TBD
}


export interface DeceasedReportStatus {
    status: ReportStatus;
    deceased:Deceased;
    lookup: PrimaryReportLookup
}

