import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nipValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const nip = control.value;

    if (!nip) return null; // Puste pole = brak błędu (sprawdza to required)

    if (!/^\d{10}$/.test(nip)) {
      return { invalidNip: 'NIP musi składać się z dokładnie 10 cyfr' };
    }

    // Wagi do sumy kontrolnej NIP
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;

    for (let i = 0; i < 9; i++) {
      sum += parseInt(nip[i], 10) * weights[i];
    }

    const controlDigit = sum % 11;
    if (controlDigit !== parseInt(nip[9], 10)) {
      return { invalidNip: 'Niepoprawny numer NIP (błąd sumy kontrolnej)' };
    }

    return null; // NIP poprawny
  };
}
