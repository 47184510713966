import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductTypes } from './models/product.model';
import { LayoutBasicComponent } from './modules/core/components/layout-basic/layout-basic.component';
import { LayoutMainComponent } from './modules/core/components/layout-main/layout-main.component';
import { PrivacyComponent } from './modules/core/components/privacy/privacy.component';
import { TermsComponent } from './modules/core/components/terms/terms.component';
import { OrderPaymentStatusComponent } from './modules/orders/components/order-payment-status/order-payment-status.component';
import { OrderPreviewComponent } from './modules/orders/components/order-preview/order-preview.component';
import { OrderRequestComponent } from './modules/orders/components/order-request/order-request.component';
import { OrderComponent } from './modules/orders/components/order/order.component';
import { ReportExtendedComponent } from './modules/reports/components/report-extended/report-extended.component';
import { ReportPrimaryComponent } from './modules/reports/components/report-primary/report-primary.component';
import { ReportComponent } from './modules/reports/components/report/report.component';

const routes: Routes = [
  {
    path: '',
    title: 'mSpadek',
    component: LayoutMainComponent,
  },
  {
    path: '',
    title: 'mSpadek',
    component: LayoutBasicComponent,
    children: [
      {
        path: 'order',
        component: OrderComponent,
        children: [
          {
            path: 'primary',
            component: OrderRequestComponent,
            data: {
              productType: ProductTypes.PRIMARY,
            },
          },
          {
            path: 'extended',
            component: OrderRequestComponent,
            data: {
              productType: ProductTypes.EXTENDED,
            },
          },
          {
            path: 'edit/primary',
            component: OrderRequestComponent,
            data: {
              productType: ProductTypes.PRIMARY,
            },
          },
          {
            path: 'edit/extended',
            component: OrderRequestComponent,
            data: {
              productType: ProductTypes.EXTENDED,
            },
          },
          {
            path: 'primary/:id',
            component: OrderRequestComponent,
            data: {
              productType: ProductTypes.PRIMARY,
            },
          },
          {
            path: 'extended/:id',
            component: OrderRequestComponent,
            data: {
              productType: ProductTypes.EXTENDED,
            },
          },
          {
            path: 'preview/:id',
            component: OrderPreviewComponent,
          },
          {
            path: 'new',
            component: OrderPreviewComponent,
          },
          {
            path: ':id',
            component: OrderPaymentStatusComponent,
          },
        ],
      },
      {
        path: 'report',
        component: ReportComponent,
        children: [
          {
            path: 'primary',
            component: ReportPrimaryComponent,
            data: {
              mockData: true,
            },
          },
          {
            path: 'primary/:id',
            component: ReportPrimaryComponent,
            data: {
              mockData: false,
            },
          },
          {
            path: 'extended',
            component: ReportExtendedComponent,
          },
          {
            path: ':id',
            component: ReportComponent,
          },
        ],
      },
    ],
  },
  {
    title: 'mSpadek | Regulamin',
    path: 'terms-and-conditions',
    component: TermsComponent,
  },
  {
    title: 'mSpadek | Polityka Prywatności',
    path: 'privacy-policy',
    component: PrivacyComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
