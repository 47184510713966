<main id="main">

    <div class="inner">
        <div *ngIf="!order">
          <div class="process-content">
            <p class="process-content__highlight">Zamówienie nie znalezione.</p>
          </div>
        </div>

        <div *ngIf="order">

          <div class="process-header">
            <h2>Podsumowanie zamówienia na</h2>
            <h1 *ngIf="productType ==='PRIMARY'">Podstawowy raport spadkowy</h1>
            <h1 *ngIf="productType==='EXTENDED'">Rozszerzony raport spadkowy</h1>
          </div>

          <div class="process-content">
            <p class="process-content__highlight">Zweryfikuj poniższe informacje i opłać zamówienie.</p>

            <div class="row order-preview-details">
                <section>
                  <h3>Osoba zmarła</h3>
                  <h4 class="space-bellow">{{order.deceased.name}} {{order.deceased.lastName}}</h4>
                  <p><strong>PESEL: </strong>{{order.deceased.peselNumber}}</p>
                  <p><strong>NIP: </strong>{{order.deceased.taxId || 'brak'}}</p>
                  <p><strong>Data śmierci: </strong>{{order.deceased.dateOfDeath }}</p>
                </section>

                <section>
                    <h3>Zamawiający</h3>
                    <h4>{{order.client.name}} {{order.client.lastName}}</h4>
                    <p>{{order.payer.street}}</p>
                    <p>{{order.payer.postalCode}} {{order.payer.city}}</p>
                    <p>{{order.client.email}}</p>

                </section>

<!--                <section class="col-lg-4">-->
<!--                    <h3>Płatnik</h3>-->
<!--                    <p><strong>{{order.payer.name || 'John' }} {{order.payer.lastName || 'Doe'}}</strong></p>-->
<!--                    <p>{{order.payer.street || 'Testowa 12'}}</p>-->
<!--                    <p>{{order.payer.postalCode || '00-000'}} {{order.payer.city || 'Warszawa'}}</p>-->
<!--                </section>-->
            </div>

            <div class="row order-preview-details">
              <section class="col-4">
                  <p>Zamówienie wykonuje</p>
                  <p>Zamawiasz</p>
                  <p>Osoba zmarła</p>
                  <p>Cena do zapłacenia</p>
                  <p>Dostępne sposoby płatności</p>
                  <p>Termin płatności</p>
                  <p>Termin wykonania raportu</p>
                  <p>Dostawa raportu</p>
                  <p>Zawarcie umowy</p>
              </section>

              <section class="col-4">
                  <p>mSpadek Sp. z o.o.</p>
                  <p>{{productType.toUpperCase() === "PRIMARY" ? 'Podstawowy raport spadkowy' : 'Rozszerzony raport spadkowy'}}</p>
                  <p>{{order.deceased.name}} {{order.deceased.lastName}}</p>
                  <p>{{productType.toUpperCase() === "PRIMARY" ? '1 zł' : '49 zł'}}</p>
                  <p>karta płatnicza, przelew, BLIK</p>
                  <p>NATYCHMIASTOWY</p>
                  <p>NATYCHMIASTOWY / 14 DNI</p>
                  <p>online / e-mail</p>
                  <p>w momencie dokonania płatności</p>
              </section>
            </div>

            <div>
              <section class="col-lg-8">
                <p>Jeśli potwierdzisz to Zamówienie, opłacisz je i rozpoczniemy świadczenie dla Ciebie usługi przed upływem terminu odstąpienia od umowy nie będzie możliwe odstąpienie od umowy.
                   Potwierdzając zamówienie akceptujesz warunki Regulaminu.
                </p>
              </section>
              <section class="col-lg-4"></section>
            </div>


            <div class="row action">
              <div class="col-lg-12 text-center buttons">
                <button class="cta cta-white product__button" (click)="navigate(orderEditUrl)">
                  Popraw dane
                </button>
                <button class="cta primary product__button" (click)="onSubmit()">
                  Potwierdzam i płacę
                </button>
              </div>
            </div>

           </div>

        </div>

    </div>
</main>
