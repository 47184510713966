import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AdvantagesComponent } from './components/advantages/advantages.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InstitutionsComponent } from './components/institutions/institutions.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { LayoutBasicComponent } from './components/layout-basic/layout-basic.component';
import { LayoutMainComponent } from './components/layout-main/layout-main.component';
import { ProductsComponent } from './components/products/products.component';
import { QuestionCtaComponent } from './components/question-cta/question-cta.component';
import { SplashComponent } from './components/splash/splash.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { StepsTwoComponent } from './components/steps-two/steps-two.component';
import { StepsComponent } from './components/steps/steps.component';
import { TermsComponent } from './components/terms/terms.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ValuesComponent } from './components/values/values.component';
import { WarnComponent } from './components/warn/warn.component';

@NgModule({
  declarations: [
    LayoutBasicComponent,
    LayoutMainComponent,
    FooterComponent,
    HeaderComponent,
    TermsComponent,
    InstitutionsComponent,
    FaqComponent,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LayoutMainComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    SplashComponent,
    QuestionCtaComponent,
    StepsComponent,
    AdvantagesComponent,
    AdvertisementComponent,
    InstructionsComponent,
    ProductsComponent,
    WarnComponent,
    StepsTwoComponent,
    StatisticsComponent,
    TestimonialsComponent,
    ValuesComponent,
    ScrollToModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {}
