import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-products',
  standalone: true, imports: [NgIf, RouterLink],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent {

}
