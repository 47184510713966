<main id="main">

    <div class="inner">
        <div *ngIf="!order">
          <div class="process-content">
            <p class="process-content__highlight">Zamówienie nie znalezione.</p>
          </div>
        </div>

        <div *ngIf="order">

          <div class="process-header">
            <h2>Potwierdzenie zamówienia na</h2>
            <h1 *ngIf="productType ==='PRIMARY'">Podstawowy raport spadkowy</h1>
            <h1 *ngIf="productType==='EXTENDED'">Rozszerzony raport spadkowy</h1>
          </div>

          <div class="process-content">
            <p class="process-content__highlight">Zweryfikuj poniższe informacje i opłać zamówienie.</p>

            <div class="row order-preview-details">
                <section class="col-lg-4">
                    <h3>Zmarły</h3>
                    <p><strong>{{order.deceased.name}} {{order.deceased.surname}}</strong></p>
                    <p>{{order.deceased.pesel}} | {{order.deceased.nip}} </p>
                </section>

                <section class="col-lg-4">
                    <h3>Zamawiający</h3>
                    <p><strong>{{order.client.name}} {{order.client.surname}}</strong></p>
                    <p>{{order.client.email}}</p>
                </section>

                <section class="col-lg-4">
                    <h3>Płatnik</h3>
                    <p><strong>{{order.payer.name || 'John' }} {{order.payer.surname || 'Doe'}}</strong></p>
                    <p>{{order.payer.street || 'Testowa 12'}}</p>
                    <p>{{order.payer.zipCode || '00-000'}} {{order.payer.city || 'Warszawa'}}</p>
                </section>
            </div>

            <div class="row action">
              <div class="col-lg-12 text-center buttons">
                <button class="cta cta-white product__button" (click)="navigate(orderEditUrl)">
                  Popraw dane
                </button>
                <button class="cta primary product__button" (click)="onSubmit()">
                  Opłać zamówienie
                </button>
              </div>
            </div>

           </div>

        </div>

    </div>
</main>
