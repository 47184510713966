import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  title!: string;
  message!: string;
  cancel?: string = 'Anuluj';
  confirm?: string = 'Ok';
  result!: Subject<boolean>;
  sanitizedMessage!: SafeHtml;

  constructor(
    public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer,
  ) {}
  ngOnInit(): void {
    this.sanitizedMessage = this.sanitizer.bypassSecurityTrustHtml(
      this.message,
    ); // Sanitize HTML
  }

  onConfirm(): void {
    this.result.next(true); // Emit true
    this.bsModalRef.hide();
  }

  onCancel(): void {
    this.result.next(false); // Emit false
    this.bsModalRef.hide();
  }
}
