<header class="container">
    <main class="main">
        <div class="main__image">
            <img src="assets/images/splash.jpg" srcset="assets/images/splash.jpg 1x, assets/images/splash@2.jpg 2x" alt="splash image">
            <h1>mSpadek pomagamy odnaleźć się w sprawach spadkowych i dziedziczeniu.</h1>
        </div>
        <div class="main__stamp">
            <img src="assets/images/logo_stamp.svg" alt="logo stamp"/>
        </div>
    </main>
</header>
