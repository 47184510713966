import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() showCertificates = true;
  footerForm: FormGroup;
  apiErrors: string[] = [];
  isMessageSent: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _contactService: ContactService,
  ) {
    this.footerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      message: ['', Validators.required],
      consentToElectronicCommunications: [false, Validators.requiredTrue],
    });
  }

  public sendMessage() {
    this.apiErrors = [];
    this.isMessageSent = false;
    if (this.footerForm.invalid) {
      this.footerForm.markAllAsTouched();
      return;
    }

    const values = this.footerForm.getRawValue();
    const [name, lastName] = values.name.split(' ');
    const payload = {
      name: name,
      lastName: lastName || null,
      email: values.email,
      message: values.message,
      consentToElectronicCommunications:
        values.consentToElectronicCommunications,
    };
    this._contactService.sendMessage(payload).subscribe(
      (data) => {
        this.footerForm.reset();
        this.isMessageSent = true;
      },
      (error) => {
        this.isMessageSent = false;
        this.apiErrors = error.error.errors.map(
          ({ slug }: { slug: string }) => slug,
        );
      },
    );
  }
}
