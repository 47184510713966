import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function MatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (!control || !matchingControl) {
      return null;
    }

    // Jeśli `matchingControl` ma już inne błędy, nie nadpisujemy ich
    if (matchingControl.errors && !matchingControl.errors['notMatch']) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ ...matchingControl.errors, notMatch: true });
      return { notMatch: true };
    } else {
      // Nie usuwamy innych błędów, tylko `notMatch`
      const { notMatch, ...otherErrors } = matchingControl.errors || {};
      matchingControl.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
    }

    return null;
  };
}
// import { AbstractControl, FormGroup } from '@angular/forms';
//
// export function MatchValidator(
//   controlName: string,
//   matchingControlName: string,
// ) {
//   return (formGroup: AbstractControl) => {
//     const control = (formGroup as FormGroup).controls[controlName];
//     const matchingControl = (formGroup as FormGroup).controls[
//       matchingControlName
//     ];
//     if (matchingControl.errors && !matchingControl.errors) {
//       return;
//     }
//     if (control.value !== matchingControl.value) {
//       matchingControl.setErrors({ notMatch: true });
//     } else {
//       matchingControl.setErrors(null);
//     }
//   };
// }
