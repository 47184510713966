<section class="container">
  <main class="main">
    <h2>
      Nasze wartości
    </h2>

    <div class="values">
      <div class="values__item">
        <img src="assets/images/iconography/reliability.svg" alt="icon"/>
        <h3>Wiarygodność</h3>
        <p class="align-content-center">
          Używamy tylko sprawdzonych publicznych i prywatnych rejestrów i baz danych.
        </p>
      </div>
      <div class="values__item">
        <img src="assets/images/iconography/velocity.svg" alt="icon"/>
        <h3>Szybkość</h3>
        <p class="align-content-center">
          mSpadek ma oszczędzać Twój czas i pieniądze..
        </p>
      </div>
      <div class="values__item">
        <img src="assets/images/iconography/usability.svg" alt="icon"/>
        <h3>Użyteczność</h3>
        <p class="align-content-center">
          Dostarczona informacja i usługi dodatkowe w swej formie i treści mają być wartościowe dla Ciebie jako użytkownika.
        </p>
      </div>
    </div>

    <div class="profits">

      <div class="profits__image-container">
        <div class="profits__image">
          <img src="assets/images/phone_question@2x.png" alt="icon"/>
        </div>
      </div>
      <div class="profits__text">
        <h3>Główne korzyści które daje mSpadek</h3>
        <ul>
          <li>
            <p>
              Poznasz sytuację osoby zmarłej w bazach i rejestrach (np. majątek i długi)
            </p>
          </li>
          <li>
            <p>
              Szybko otrzymasz informacje. Oszczędzisz czas
            </p>
          </li>
          <li>
            <p>
              Sprawniej załatwisz formalności spadkowe np. w sądzie i urzędzie skarbowym
            </p>
          </li>
          <li>
            <p>
              Łatwiej zabezpieczysz lub odzyskasz majątek spadkowy
            </p>
          </li>
          <li>
            <p>
              Korzystniej podzielisz spadek, jeśli dziedziczysz go z innymi osobami i nie jesteś jedynym spadkobiercą
            </p>
          </li>
        </ul>

      </div>

    </div>

  </main>

</section>

