import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { ActivatedRoute } from '@angular/router';
import { DeceasedReport } from '../../../../models/report.model';
import { getDateDaysAgo } from '../../../shared/utils/date.utils';

@Component({
  selector: 'app-report-mini',
  templateUrl: './report-primary.component.html',
  styleUrls: ['./report-primary.component.scss']
})
export class ReportPrimaryComponent implements OnInit {

  public report!:DeceasedReport;
  private _reportId ='';
  public useMockData = false;

  constructor(
      private _reportService:ReportService,
      private _route:ActivatedRoute
  ) {
  }


  ngOnInit() {
    this.useMockData = this._route.snapshot.data['mockData'];

    this._reportId = this._route.snapshot.params['id'];

    this._reportService.getReport(this._reportId).subscribe( response => {
      this.report = response.data;
      this._reportService.reportResponse.next(response);
    });

    if (this.useMockData) {
      this.report = {
        deceased: {
          name: 'Helena',
          surname: 'Kowalska',
          fatherName: 'Wiesław',
          motherName: 'Grażyna',
          pesel: '00000000000',
          nip: '0000000000',
          dateOfDeath: getDateDaysAgo(new Date(), 12)
        },
        databaseLookupFinished: {
          krs: true,
          ceidg: true,
          crbr: true,
          krz: true,
          msig: true,
          vatWl: true,
          sudop: true
        },
        updatedAt: getDateDaysAgo(new Date(), 1),
        report: {
          krs: {
            propertyRights: true,
            companyAuthorities: true
          },
          ceidg: true,
          crbr: false,
          krz: true,
          msig: true,
          vatWl: true,
          sudop: false
        },

      } as DeceasedReport;
    }


  }

  public getDBStatusDate(date: Date): Date | null {
      return getDateDaysAgo(date, 1);
  }


  public generatePdf() {
    if(this.useMockData) {
      return;
    }
    this._reportService.getReportPDF(this._reportId);

  }
}
