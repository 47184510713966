<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
        <button type="button" class="btn-close" aria-label="Close"></button>
      </div>
        <div class="modal-body" [innerHTML]="sanitizedMessage"></div>
      <div class="modal-footer">
        <button type="button" class="cta cta-white btn" (click)="onCancel()">{{cancel}}</button>
        <button type="button" class="cta primary btn" (click)="onConfirm()">{{confirm}}</button>
      </div>
    </div>
  </div>
</div>
