<app-header [showNavigation]="true"></app-header>
<main class="content">
  <div class="main-container">
      <app-splash/>
      <app-question-cta/>
      <app-steps/>
      <app-advantages/>
      <app-advertisement/>
      <app-instructions/>
      <app-products/>
      <app-warn/>
      <app-faq/>
      <app-steps-two/>
      <app-statistics/>
      <app-testimonials/>
      <app-values/>
  </div>
</main>
<app-footer [showCertificates]="true"></app-footer>
