<app-header/>

<section class="container">
  <main class="main">
    <h1 class="title">Regulamin</h1>

    <ol class="main-section">
      <li class="main-section__title"  *ngFor="let section of terms" >{{section.title}}
        <p class="main-section__content" *ngIf="section.text">{{section.text}}</p>
        <div *ngIf="section.list_type === 'ol'; else unorderedList">
          <ol class="padding-left">
            <li class="main-section__content" *ngFor="let item of section.content">
              <span *ngIf="item['text']" [innerHTML]="item['text']"></span>
              <span *ngIf="item['term']" [innerHTML]="'<b>'+item['term']+'</b> - '+item['definition']"></span>
            </li>
          </ol>
        </div>
        <ng-template #unorderedList>
          <ul class="padding-left">

            <li class="main-section__content" *ngFor="let item of section.content">
              <span *ngIf="item['text']" [innerHTML]="item['text']"></span>
              <span *ngIf="item['term']" [innerHTML]="'<b>'+item['term']+'</b> - '+item['definition']"></span>
              </li>
          </ul>
        </ng-template>
      </li>
    </ol>
  </main>
</section>
<app-footer/>
