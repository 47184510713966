<app-header/>

<section class="container">
  <main class="main">
    <h1 class="title">Polityka Prywatności</h1>

    <ol class="main-section">
      <li class="main-section__title"  *ngFor="let section of privacy"  [class.break-page]="section.breakPage">{{section.title}}
        <p class="main-section__content" *ngFor="let paragraph of section['content']" [innerHTML]="paragraph.text"></p>
      </li>
    </ol>
  </main>
</section>
<app-footer/>

