<section class="container">
    <main class="main">
        <div class="info">
            <h2>Nie wiesz czy przyjąć,<br>czy odrzucić spadek?</h2>


            <p>Potrzebujesz informacji, czy spadkodawca pozostawił jakieś aktywa lub długi np. w stosunku do Skarbu Państwa?</p>
            <p>
                Sprawdź osobę zmarłą w wielu bazach i rejestrach. Raport spadkowy pomoże Ci w podjęciu decyzji.
            </p>

            <a class="cta cta--bold info__cta" routerLink="/order/primary" routerLinkActive="active">Zamów raport</a>

        </div>

        <div class="image">
            <img src="assets/images/phone_question.png" alt="phone image">
        </div>

    </main>
</section>

<!--srcset="assets/images/phone_question.png 1x, assets/images/phone_question@2x.png 2x"  -->
