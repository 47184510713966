import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ConfirmationModalService } from 'src/app/modules/shared/services/confirmation-modal.service';
import { Order, OrderRequest } from '../../../../models/order.model';
import { ProductType, ProductTypes } from '../../../../models/product.model';
import { getDateString } from '../../../shared/utils/date.utils';
import { MatchValidator } from '../../../shared/validators/match.validator';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.scss'],
})
export class OrderRequestComponent implements OnInit {
  @ViewChild('confirmImmediateExecution') confirmModal!: any;

  public orderForm!: FormGroup;
  public acceptAll: boolean = false;
  public productType!: ProductType;
  public order!: Order;
  private _popups = { immediateExecution: true, noNip: true };

  constructor(
    private _formBuilder: FormBuilder,
    private _orderService: OrderService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cd: ChangeDetectorRef,
    private confirmationService: ConfirmationModalService,
  ) {}

  async ngOnInit() {
    this._createForm();
    this.productType = !!this._route.snapshot.url.find((item) =>
      item.path.includes('extended'),
    )
      ? ProductTypes.EXTENDED
      : ProductTypes.PRIMARY;

    const currentState = this._router.lastSuccessfulNavigation;
    this.order = currentState?.extras?.state?.['data'];
    if (this.order?.client.email) {
      this.orderForm.patchValue({
        name: this.order.deceased.name,
        surname: this.order.deceased.surname,
        pesel: this.order.deceased.pesel,
        nip: this.order.deceased.nip,
        dateOfDeath: getDateString(this.order.deceased.dateOfDeath),
        clientName: this.order.client.name,
        clientSurname: this.order.client.surname,
        clientEmail: this.order.client.email,
        clientEmailConfirm: this.order.client.email,
        payerName: this.order.payer.name,
        payerSurname: this.order.payer.surname,
        payerStreet: this.order.payer.street,
        payerZipCode: this.order.payer.zipCode,
        payerCity: this.order.payer.city,
        termsAndConditions: Boolean(this.order.consent.termsAndConditions),
        verificationOfTheDeceased: Boolean(
          this.order.consent.verificationOfTheDeceased,
        ),
        consentToElectronicCommunications: Boolean(
          this.order.consent.consentToElectronicCommunications,
        ),
        marketingAndCommercialConsent: Boolean(
          this.order.consent.marketingAgreement,
        ),
        immediateExecution: Boolean(this.order.consent.immediateExecution),
      });
    }
  }

  private _createForm() {
    this.orderForm = this._formBuilder.group(
      {
        name: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        surname: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        pesel: new FormControl('', [
          Validators.required,
          Validators.minLength(11),
        ]),
        nip: new FormControl('', []),
        dateOfDeath: new FormControl('', [Validators.required]),
        // fatherName: new FormControl('Janusz', [Validators.minLength(3)]),
        // motherName: new FormControl('Grazyna', [Validators.minLength(3)]),
        // motherMaidenName: new FormControl('Nowak', [Validators.minLength(3)]),
        clientName: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        clientSurname: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        clientEmail: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        clientEmailConfirm: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),

        payerName: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        payerSurname: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        payerStreet: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        payerZipCode: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        payerCity: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),

        acceptAll: new FormControl(false),
        termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
        verificationOfTheDeceased: new FormControl(false, [
          Validators.requiredTrue,
        ]),
        consentToElectronicCommunications: new FormControl(false, [
          Validators.requiredTrue,
        ]),
        marketingAndCommercialConsent: new FormControl(false),
        immediateExecution: new FormControl(false),
      },
      { validators: MatchValidator('clientEmail', 'clientEmailConfirm') },
    );
  }

  private _makeOrder() {
    const values = this.orderForm.getRawValue();
    const order: OrderRequest = {
      product: this.productType,
      client: {
        name: values.clientName,
        surname: values.clientSurname,
        email: values.clientEmail,
      },
      payer: {
        name: values.payerName,
        surname: values.payerSurname,
        street: values.payerStreet,
        zipCode: values.payerZipCode,
        city: values.payerCity,
      },
      deceased: {
        name: values.name,
        surname: values.surname,
        pesel: values.pesel,
        nip: values.nip ? values.nip : null,
        dateOfDeath: values.dateOfDeath,
      },
      consent: {
        termsAndConditions: values.termsAndConditions,
        verificationOfTheDeceased: values.verificationOfTheDeceased,
        consentToElectronicCommunications:
          values.consentToElectronicCommunications,
        marketingAgreement: values.marketingAndCommercialConsent,
        immediateExecution: values.immediateExecution,
      },
    };

    const navigationExtras: NavigationExtras = {
      state: {
        data: order,
      },
    };
    this._router.navigate(['order', 'new'], navigationExtras);

    // this._orderService.orderRequest.next(order);
    // this._orderService
    //   .createOrder(order)
    //   .subscribe((data: ResponseSingle<OrderResponse>) => {
    //     this._orderService.orderResponse.next(data);
    //     this._router.navigate(['order', 'preview', data.data.token]);
    //   });
  }

  public onSubmit() {
    if (this._popups.immediateExecution) {
      this.confirmationService
        .confirm({
          title: 'Jesteś pewna/y?',
          message: `<p>Jeżeli nie wyrazisz zgody na na rozpoczęcie wykonywania usługi, <strong>raport dostarczymy dopiero za 14 dni</strong>. </p>`,
          confirm: 'Tak, kontynuuj',
          cancel: 'Zaznaczę co trzeba',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this._popups = { ...this._popups, immediateExecution: false };
            this.onSubmit();
          }
        });
    }

    if (this._popups.noNip) {
      this.confirmationService
        .confirm({
          title: 'Jesteś pewna/y?',
          message: `<p>W przypadku braku numeru <strong>NIP</strong> nie będzie możliwa weryfikacja niektórych baz i rejestrów.</p>`,
          confirm: 'Tak, kontynuuj!',
          cancel: 'Podaję NIP',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this._popups = { ...this._popups, noNip: false };
            this.onSubmit();
          }
        });
    }
    this._makeOrder();
  }

  public onAcceptAllChanged(event: any) {
    this.orderForm.patchValue({
      termsAndConditions: event.target.checked,
      verificationOfTheDeceased: event.target.checked,
      consentToElectronicCommunications: event.target.checked,
      marketingAndCommercialConsent: event.target.checked,
      immediateExecution: event.target.checked,
    });
    this._cd.detectChanges();
  }

  public onSamePaymentData(event: any) {
    const values = this.orderForm.getRawValue();
    if (event.target.checked) {
      this.orderForm.patchValue({
        payerName: values.clientName,
        payerSurname: values.clientSurname,
      });
      this.orderForm.get('payerName')?.disable();
      this.orderForm.get('payerSurname')?.disable();
    } else {
      this.orderForm.patchValue({
        payerName: '',
        payerSurname: '',
      });
      this.orderForm.get('payerName')?.enable();
      this.orderForm.get('payerSurname')?.enable();
    }
    this._cd.detectChanges();
  }

  public onFormChange() {
    const values = this.orderForm.getRawValue();
    if (
      values.termsAndConditions &&
      values.verificationOfTheDeceased &&
      values.consentToElectronicCommunications &&
      values.marketingAndCommercialConsent &&
      values.immediateExecution
    ) {
      this.orderForm.patchValue({
        acceptAll: true,
      });
      this._cd.detectChanges();
    } else {
      this.orderForm.patchValue({
        acceptAll: false,
      });
      this._cd.detectChanges();
    }
    if (!values.immediateExecution) {
      this._popups = { ...this._popups, immediateExecution: true };
    } else {
      this._popups = { ...this._popups, immediateExecution: false };
    }
    if (!values.nip || !values.nip.length) {
      this._popups = { ...this._popups, noNip: true };
    } else {
      this._popups = { ...this._popups, noNip: false };
    }
  }
}
