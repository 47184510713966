import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-steps',
  standalone: true, imports: [NgIf],
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.scss'
})
export class StepsComponent implements OnInit {
  isMobile = false;

  constructor() {
    window.addEventListener('resize', () => {
      this._onWindowResize();
    });
  }

  ngOnInit() {
   this._onWindowResize();
  }

  private _onWindowResize() {
    this.isMobile = window.innerWidth <= 1415;
  }
}
