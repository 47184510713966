<section class="container">
    <main class="main">
        <div class="info">
            <h2>Jedyna taka usługa na rynku.</h2>
            <h2>Oszczędzamy Twój czas i pieniądze.</h2>
        </div>
        <div class="image">
            <img *ngIf="isMobile" src="assets/images/family-sunset.jpg" alt="Family image"/>
        </div>
    </main>
</section>

