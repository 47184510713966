<div id="main">

  <div class="process-header">
    <h1>Sytuacja osoby zmarłej w publicznych bazach danych</h1>
  </div>

  <div class="process-content">

    <div class="inner">

      <div class="report">
        <div class="report-header">
          <div class="report-header-top">
            <p>
              <strong>Osoba zmarła: Jan Kowalski</strong>
            </p>

            <p>
              <strong>PESEL:</strong>
              <span>1234566</span>
            </p>

            <p>
              <strong>NIP:</strong>
              <span>1234567</span>
            </p>

            <p>
              <strong>Data zgonu:</strong>
              <span>12/12/2022</span>
            </p>

            <p class="report-notice">
              <span>Wygenerowano na podstawie informacji z publicznych baz danych</span>
              <span>Data wygenerowania informacji z baz danych: 11.11.2023 - 09:54</span>
            </p>

          </div>
          <div class="actions">
            <input type="button" value="Pobierz raport" class="cta cta--disabled" />
          </div>
        </div>
      </div>

        <div class="report">

            <div class="report-section">
                <h3>CZY OSOBA ZMARŁA PROWADZIŁA DZIAŁALNOŚĆ GOSPODARCZĄ: <span>TAK</span></h3>
                <p>
                    Baza: Centralna Ewidencja i Informacja o Działalności Gospodarczej.
                </p>
                <p>
                    Prowadzenie działalności gospodarczej wiąże się z możliwością występowania aktywów, ale również z możliwością występowania
                    zadłużenia w stosunku do administracji publicznej np. ZUS, US lub kontrahentów. Szczegóły w załączniku nr 1.
                </p>
                <div class="table-wrapper">
                    <table class="alt db-result">
                        <thead>
                            <tr>
                                <th>Nazwa firmy</th>
                                <th>NIP</th>
                                <th>Status</th>
                                <th>Spółka&nbsp;cywilna</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Marcin Molak Business Consulting</td>
                                <td>1234567</td>
                                <td>Zawieszony</td>
                                <td>Nie</td>
                            </tr>
                            <tr>
                                <td>Miejsce wykonywania działalności</td>
                                <td colspan="3">ul. Marszałkowska 1 m.123, 03-123 Warszawa</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="report-section">
                <h3>CZY OSOBA ZMARŁA POSIADAŁA PRAWA WŁASNOŚCI W SPÓŁKACH?* <span>TAK</span></h3>
                <p>
                    Baza: Krajowy Rejestr Sądowy.
                </p>
                <p>
                    Posiadanie udziałów w spółkach jest aktywem niemniej jednak jest to uzależnione od kondycji danej spółki. Szczegóły w załączniku nr 2.
                </p>
                <div class="table-wrapper">
                    <table class="alt db-result">
                        <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Forma prawna</th>
                                <th>% udziałów lub akcji</th>
                                <th>Wartość udziałów lub akcji w PLN</th>
                                <th>KRS</th>
                                <th>Wpis&nbsp;o&nbsp;zaległościach, wierzytelnościach, likwidacji?***</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of companies">
                                <td>{{x.name}}</td>
                                <td>{{x.type}}</td>
                                <td>{{x.shares}}</td>
                                <td>{{x.value | number}}</td>
                                <td>{{x.krs}}</td>
                                <td>{{x.entries}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="note">
                <p class="report-notice">
                    * Prawa własności to np. udziały lub akcje. Pamiętaj, że jeżeli osoba zmarła posiadała samodzielnie lub łącznie z innymi osobami mniej
                    niż 10% udziałów spółki z ograniczoną odpowiedzialnością lub nie posiadała wszystkich akcji spółki akcyjnej to takie informacje nie są
                    publikowane i nie są widoczne w Dziale 1 KRS. Dlatego nawet jeżeli powyżej nie ukazuje się żadna spółka z ograniczoną
                    odpowiedzialnością lub spółka akcyjna to nie oznacza to, że osoba zmarła nie była udziałowcem lub akcjonariuszem żadnego
                    przedsiębiorstwa.
                  <br>
                  <br>
                    *** To jest informacja o potencjalnym zadłużeniu spółki. Znajdziesz ja w Dziale 4 wypisu z KRS w załączniku nr 2
                </p>
            </div>
            <div class="report-section">
                <h3>CZY OSOBA ZMARŁA PEŁNIŁA FUNKCJE WE WŁADZACH SPÓŁKI NP. JAKO CZŁONEK ZARZĄDU SPÓŁKI LUB JEJ WSPÓLNIK? <span>TAK</span></h3>
                <p>
                    Baza: Krajowy Rejestr Sądowy.
                </p>
                <p>
                    Pełnienie niektórych funkcji w spółce może się wiązać z odpowiedzialnością osobistą za zobowiązania spółki. Może to być potencjalne zródło długów osoby zmarłej. Szczegóły w załączniku nr 2.
                </p>
                <div class="table-wrapper">
                    <table class="alt db-result">
                        <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Forma prawna</th>
                                <th>KRS</th>
                                <th>Funkcja</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of companies">
                                <td>{{x.name}}</td>
                                <td>{{x.type}}</td>
                                <td>{{x.krs}}</td>
                                <td>{{x.position}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="report-section">
                <h3>CZY OSOBA ZMARŁA WYSTĘPUJE W POZOSTAŁYCH BAZACH DANYCH?</h3>
                <p>
                    Fakt występowania w poniższych bazach danych lub publikatorach może wskazywać na zródło długów lub majątku osoby zmarłej.
                </p>
                <div class="table-wrapper">
                    <table class="alt db-result">
                        <tbody>
                            <tr>
                                <td>
                                    <b>CZY OSOBA ZMARŁA OTRZYMAŁA POMOC PUBLICZNĄ?</b><br/>
                                    Baza: SUDOP.<br/>
                                    Pewne formy pomocy publicznej np. odroczenie zapłaty mandatu lub kary, mogą być źrodłem długów osoby zmarłej jeżeli nie zostały zapłacone. Szczegóły w załączniku nr 3.
                                </td>
                                <td><b>TAK</b></td>
                                <td>Kwota
                                    12.340 PLN
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>CZY OSOBA ZMARŁA WYSTĘPUJE W WYKAZIE PODATNIKÓW PODATKU VAT, CZYLI BIAŁEJ LIŚCIE PODATNIKÓW VAT?</b><br/>
                                    Baza: Wykaz Podatników Podatku VAT.<br/>
                                    To jest informacja o posiadaniu przez osobę zmarłą statusu podatnika podatku VAT. Rodzaj
                                    tego statusu może oznaczać posiadanie aktywów albo zobowiązań np. wobec US.
                                    Szczegóły w załączniku nr 4.
                                </td>
                                <td><b>TAK</b></td>
                                <td>
                                    Nr. NIP
                                    1130618182
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>CZY OSOBA ZMARŁA WYSTĘPUJE SIE W KRAJOWYM REJESTRZE ZADŁUŻONYCH?</b><br/>
                                    Baza: Krajowy Rejestr Zadłużonych.<br/>
                                    Występowanie osoby zmarłej w tym rejestrze może oznaczać posiadanie przez nią długów. To
                                    jest bardzo istotna informacja. Szczegóły w załączniku nr 5.
                                </td>
                                <td colspan="2"><b>NIE</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <b>CZY OSOBA ZMARŁA JEST BENEFICJENTEM SPÓŁKI, TRUSTU LUB FUNDACJI RODZINNEJ?</b><br/>
                                    Baza: Centralny Rejestr Beneficjentów Rzeczywistych.<br/>
                                    Występowanie osoby zmarłej w tym rejestrze może oznaczać posiadanie przez nią aktywów. Szczegóły w załączniku nr 6
                                </td>
                                <td><b>TAK</b></td>
                                <td>
                                    Aurexa Sp. z o.o.
                                    Marszałkowska 111
                                    Warszawa
                                    NIP:
                                    Rodzaj:
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>CZY OSOBA ZMARŁA WYSTĘPUJE W MONITORZE SĄDOWYM I GOSPODARCZYM?</b><br/>
                                    Baza: Monitor Sądowy i Gospodarczy<br/>
                                    Występowanie osoby zmarłej w MSiG może oznaczać posiadanie przez nią aktywów, ale i
                                    potencjalnych długów. Szczegóły w załączniku nr 7.
                                </td>
                                <td colspan="2"><b>NIE</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table-wrapper table-wrapper__definitions">
                <table class="alt">
                    <thead>
                    <tr>
                        <th>Definicje</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let x of definitions">
                        <td>
                            <strong>{{x.title}}</strong><br/><br>
                            {{x.desc}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
</div>
