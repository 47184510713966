import { Component } from '@angular/core';

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrl: './institutions.component.scss',
})
export class InstitutionsComponent {
  public institutions = [
    {
      name: 'Ministerstwo Sprawiedliwości',
      description:
        'Udostępnia nam informacje z baz danych używanych do przygotowania raportu np. KRS, KRZ i MSiG',
    },
    {
      name: 'Ministerstwo Rozwoju i Technologii',
      description:
        'Udostępnia nam informacje z baz danych używanych do przygotowania raportu np. KRS, KRZ i MSiG',
    },
    {
      name: 'Ministerstwo Finansów',
      description:
        'Udostępnia nam informacje z baz danych używanych do przygotowania raportu np. KRS, KRZ i MSiG',
    },
    {
      name: 'Urząd Ochrony Danych Osobowych',
      description:
        'Udostępnia nam informacje z baz danych używanych do przygotowania raportu np. KRS, KRZ i MSiG',
    },
  ];
}
