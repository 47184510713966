
<div class="footer-contact" id="contact">

  <div class="footer-contact__container footer-contact__container-centered">

    <div class="footer-contact__form">
      <h2>Kontakt</h2>

      <form (ngSubmit)="sendMessage()" class="footer-contact__form-grid" [formGroup]="footerForm">
        <div class="form-group footer-contact__name">
          <input class="form-control" type="text" id="surname" formControlName="name" placeholder="name" autocomplete="off">
          <label for="surname" class="label">imię i nazwisko</label>
        </div>
        <div class="form-group footer-contact__email">
          <input class="form-control" type="text" id="email" formControlName="email" placeholder="email" autocomplete="off">
          <label for="surname" class="label">email</label>
        </div>
        <div class="form-group footer-contact__message">
          <textarea class="form-control" name=""  formControlName="message" id="message" cols="30" rows="2" placeholder="message" autocomplete="off"></textarea>
          <label for="message" class="label">wiadomość</label>
        </div>
        <div class="form-group footer-contact__accept">
          <label for="accept" class="checkbox-label">
            <input type="checkbox" id="accept" name="accept">
            <span class="checkbox-label__text">
              Wyrażam zgodę Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto
            </span>
          </label>
        </div>
        <div class="form-group footer-contact__send-btn">
          <button class="cta cta-white footer-contact__send-btn" type="submit">Wyślij</button>
        </div>
      </form>

    </div>
  </div>
</div>

<div class="footer-media">

  <img src="assets/images/logo.svg" alt="mSpadek" class="footer-media__logo-image">

  <div class="footer-media__social">
    <a href="" target="_blank" rel="noopener noreferrer"><img src="assets/images/facebook.svg" alt="mSpadek - facebook" class="footer-media__image"></a>
    <img src="assets/images/x.svg" alt="mSpadek - x" class="footer-media__image">
    <img src="assets/images/email.svg" alt="mSpadek - email" class="footer-media__image">
  </div>

</div>

<div class="footer-payu">

  <img src="assets/images/payu.jpg" alt="payU" class="footer-payu__image">

</div>

<footer class="footer f_montserrat-normal">
    <nav class="navigation">
        <ul class="navigation__list">
            <li class="navigation__list-item">© mSpadek. All rights reserved</li>
            <li class="navigation__list-item"><a routerLink="/privacy-policy">polityka prywatności</a></li>
            <li class="navigation__list-item"><a routerLink="/terms-and-conditions">regulamin</a></li>
            <li class="navigation__list-item">powered by <a href="https://maio.sh" target="_blank" rel="oopener noreferrer">Maio SH</a></li>
            <li class="navigation__list-item">designed by <a href="https://13f.pl" rel="oopener noreferrer">13th studio</a></li>
        </ul>
    </nav>
</footer>

