import {AbstractControl, FormGroup} from '@angular/forms';

export function MatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: AbstractControl) => {
        const control = (formGroup as FormGroup).controls[controlName];
        const matchingControl = (formGroup as FormGroup).controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ notMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
