import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { ActivatedRoute } from '@angular/router';
import { DeceasedReport, PrimaryReportResultStatus, ReportStatus } from '../../../../models/report.model';
import { getDateDaysAgo } from '../../../shared/utils/date.utils';
import { interval, startWith, Subscription, switchMap, takeWhile } from 'rxjs';
import { primaryReportExample } from '../../../../examples/primary-report.example';

@Component({
  selector: 'app-report-mini',
  templateUrl: './report-primary.component.html',
  styleUrls: ['./report-primary.component.scss']
})
export class ReportPrimaryComponent implements OnInit, OnDestroy {

  public report!:DeceasedReport;
  private _reportId ='';
  private _subscription!: Subscription;
  public useMockData = false;

  constructor(
      private _reportService:ReportService,
      private _route:ActivatedRoute
  ) {
  }


  ngOnInit() {
    this.useMockData = this._route.snapshot.data['mockData'];

    this._reportId = this._route.snapshot.params['id'];

    if(!this._reportId){
      this.useMockData = true;
    }

    if (this.useMockData) {
      this.report = primaryReportExample;
      this._reportId = '';
      return;
    }

    this._subscription = interval(1000).pipe(
            startWith(0),
            switchMap(() => this._reportService.getReport(this._reportId)),
            takeWhile(response => response.data.status !== ReportStatus.PROCESSED, true)
        )
        .subscribe(response => {
          this.report = response.data;
          if (this.report.status === ReportStatus.PROCESSED) {
            this._unsubscribe();
          }
        });

  }

  public getDBStatusDate(date: Date): Date | null {
      return getDateDaysAgo(date, 1);
  }


  public generatePdf() {

    // if(this.useMockData) {
    //   return;
    // }
    this._reportService.getReportPDF(this.report, this._reportId);

  }

  public mapReportResult(result: PrimaryReportResultStatus) {
   return this._reportService.mapPrimaryReportResult(result);
  }

  ngOnDestroy() {
    this._unsubscribe();
  }

  private _unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
