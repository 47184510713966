import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PaymentStatusPipe } from './pipes/payment-status.pipe';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';

@NgModule({
  declarations: [PaymentStatusPipe, ConfirmationModalComponent],
  exports: [PaymentStatusPipe, RouterModule],
  imports: [CommonModule, ModalModule.forRoot()],
})
export class SharedModule {}
