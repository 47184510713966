import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {
  private modalRef?: BsModalRef;


  constructor(private modalService: BsModalService) {}

  confirm({
    title,
    message,
    confirm,
    cancel,
  }: {
    title: string;
    message: string;
    confirm?: string;
    cancel?: string;
  }): Observable<boolean> {
    const result = new Subject<boolean>();

    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: { title, message, confirm, cancel, result },
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      backdrop: true

    });
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    return result.asObservable();
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
      document.body.style.removeProperty('position');
    }
  }
}
