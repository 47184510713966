<section class="container">
    <main class="main">
        <h2 class="">
            Szybkie i proste:
        </h2>
        <div class="iconography-container">

            <div class="iconography">
                <img src="assets/images/iconography/choose_report.svg"/>
            </div>

            <div class="iconography">
                <img src="assets/images/iconography/fill_information.svg"/>
            </div>

            <div class="iconography">
                <img src="assets/images/iconography/check_data.svg"/>
            </div>

            <div class="iconography">
                <img src="assets/images/iconography/pay_online.svg"/>
            </div>

            <div class="iconography">
                <img src="assets/images/iconography/check_email.svg"/>
            </div>

            <p class="iconography__infio-2">Wybierz raport</p>
            <p class="iconography__infio-4">Wpisz podstawowe infomracje</p>
            <p class="iconography__infio-6">Potwierdź dane</p>
            <p class="iconography__infio-8">Zapłać online</p>
            <p>Sprawdź e-mail, przeglądaj raport i podejmij decyzję!</p>

        </div>
    </main>
</section>
