import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { OrdersModule } from './modules/orders/orders.module';
import { SharedModule } from './modules/shared/shared.module';
import { ReportsModule } from './modules/reports/reports.module';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
registerLocaleData(localePl);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ScrollToModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    OrdersModule,
    SharedModule,
    ReportsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
      { provide: LOCALE_ID, useValue: 'pl' },
      { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
