
<div class="footer-contact" id="contact">

  <div class="footer-contact__container footer-contact__container-centered">

    <div class="footer-contact__form">
      <h2>Kontakt</h2>

      <div class="alert-success" *ngIf="isMessageSent">
        <strong>Wiadomość została wysłana. Dziękujemy!</strong>
      </div>

      <form (ngSubmit)="sendMessage()" class="footer-contact__form-grid" [formGroup]="footerForm">
        <div class="form-group footer-contact__name">
          <input class="form-control" type="text" id="name" formControlName="name" placeholder="name" autocomplete="off">
          <label for="name" class="label">imię i nazwisko</label>
          <div *ngIf="(footerForm.get('name')?.invalid && footerForm.get('name')?.touched) || apiErrors.includes('name_invalid')" class="form-group__error-message">
            <span *ngIf="footerForm.get('name')?.errors?.['required']">Imię i nazwisko jest wymagane</span>
            <span class="form-control-error" *ngIf="apiErrors.includes('name_invalid')">Imię i nazwisko jest niepoprawne</span>
          </div>
        </div>
        <div class="form-group footer-contact__email">
          <input class="form-control" type="text" id="email" formControlName="email" placeholder="email" autocomplete="off">
          <label for="email" class="label">email</label>
          <div *ngIf="(footerForm.get('email')?.invalid && footerForm.get('email')?.touched) || apiErrors.includes('email_invalid')" class="form-group__error-message">
            <span *ngIf="footerForm.get('email')?.errors?.['required']">Email jest wymagany</span>
            <span class="form-control-error" *ngIf="apiErrors.includes('email_invalid')">Email jest niepoprawny</span>
          </div>
        </div>
        <div class="form-group footer-contact__message">
          <textarea class="form-control" name="message"  formControlName="message" id="message" cols="30" rows="2" placeholder="message" autocomplete="off"></textarea>
          <label for="message" class="label">wiadomość</label>
          <div *ngIf="(footerForm.get('message')?.invalid && footerForm.get('message')?.touched) || apiErrors.includes('message_invalid')" class="form-group__error-message">
            <span *ngIf="footerForm.get('message')?.errors?.['required']">Wiadomość jest wymagana</span>
            <span class="form-control-error" *ngIf="apiErrors.includes('message_invalid')">Wiadomość jest niepoprawna</span>
          </div>
        </div>
        <div class="form-group footer-contact__accept">
          <label for="accept" class="checkbox-label">
            <input type="checkbox" id="accept" name="consentToElectronicCommunications" formControlName="consentToElectronicCommunications">
            <span class="checkbox-label__text">
              Wyrażam zgodę na kontaktowanie się ze mną przez mSpadek.pl poprzez e-mail oraz komunikatory internetowe w celu udzielenia odpowiedzi na moją korespondencję. Potwierdzam zapoznanie się z Regulaminem i Polityką prywatności.
            </span>
            <div *ngIf="(footerForm.get('consentToElectronicCommunications')?.invalid && footerForm.get('consentToElectronicCommunications')?.touched) || apiErrors.includes('consentToElectronicCommunications_invalid')" class="form-group__error-message">
              <span *ngIf="footerForm.get('consentToElectronicCommunications')?.errors?.['required']">Zgoda jest wymagana</span>
            </div>
          </label>
        </div>
        <div class="form-group footer-contact__send-btn">
          <button class="cta cta-white footer-contact__send-btn" type="submit">Wyślij</button>
        </div>
      </form>

    </div>
  </div>
</div>

<div class="footer-media">

  <img src="assets/images/logo.svg" alt="mSpadek" class="footer-media__logo-image">

  <div class="footer-media__social">
    <a href="https://www.facebook.com/profile.php?id=61560548742415" target="_blank" rel="noopener noreferrer"><img src="assets/images/facebook.svg" alt="mSpadek - facebook" class="footer-media__image"></a>
    <a href="mailto:kontakt@mspadek.pl"><img src="assets/images/email.svg" alt="mSpadek - email" class="footer-media__image"></a>
  </div>

</div>

<div class="footer-payu">

  <img src="assets/images/payu.jpg" alt="payU" class="footer-payu__image">

</div>

<div class="footer-asterisk">
  <p><span class="asterisk">*</span>Opcja będzie dostępna już wkrótce.</p>
</div>

<footer class="footer f_montserrat-normal">
    <nav class="navigation">
        <ul class="navigation__list">
            <li class="navigation__list-item">© mSpadek. All rights reserved</li>
            <li class="navigation__list-item"><a routerLink="/privacy-policy">polityka prywatności</a></li>
            <li class="navigation__list-item"><a routerLink="/library">do pobrania</a></li>
            <li class="navigation__list-item">powered by <a href="https://maio.sh" target="_blank" rel="oopener noreferrer">Maio SH</a></li>
            <li class="navigation__list-item">designed by <a href="https://13f.pl" rel="oopener noreferrer">13th studio</a></li>
        </ul>
    </nav>
</footer>

