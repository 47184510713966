import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { Order } from '../../../../models/order.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-payment-status',
  templateUrl: './order-payment-status.component.html',
  styleUrls: ['./order-payment-status.component.scss']
})
export class OrderPaymentStatusComponent implements OnInit{
  public order:Order | undefined;
  public isMale: boolean = false;
  public reportId:string = '';

  constructor(
      private _orderService:OrderService,
      private _route:ActivatedRoute
  ){}


  ngOnInit ():void {
   const orderId  = this._route.snapshot.params['id'];
    this._orderService.getOrder( orderId).subscribe( data => {
      this.order = data.data;
      this.reportId = data.data.report.token;
      this.isMale = this._orderService.getSexFromPesel(data.data.deceased.peselNumber) === 'male';
    });

  }
}
