import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],

})
export class FaqComponent {
  public questions = [
    { title: 'Czym jest mSpadek?', answer: 'mSpadek jest serwisem internetowym umożliwiającym szybkie uzyskanie informacji w sprawach związanych ze spadkiem i zachowkiem, w tym wygenerowanie raportu o osobie zmarłej.', isOpen: false },
    { title: 'Co sprawdza raport?', answer: 'Raport weryfikuje wybrane publiczne i prywatne rejestry i bazy danych, w których mogła występować\n' + 'osoba zmarła.', isOpen: false },
    { title: 'Czego się dowiem z raportu?', answer: 'Otrzymasz informacje w jakich wybranych bazach i rejestrach' +
          ' występuje osoba zmarła. W zależności\n' + 'od rodzaju raportu dowiesz się jakie to ma konsekwencje prawne i jakie ryzyko albo potencjał się z\n' + 'tym wiąże.', isOpen: false },
    { title: 'Co zawiera raport?', answer: 'Raport zawiera wybrane informacje na temat sytuacji osoby zmarłej. W' +
          ' zależności od tego, który\n' + 'raport wybierzesz informacje te będą albo ogólne albo bardziej szczegółowe, w tym uzupełnione o\n' + 'komentarze, wyjaśnienia i sugestie. Możesz wybrać Raport Podstawowy, Raport Rozszerzony albo\n' + 'Raport Pełny.', isOpen: false },
    { title: 'Jak generowany jest raport i skąd pochodzą dane?', answer: 'Dane pochodzą głównie z urzędowych rejestrów publicznych prowadzonych przez instytucje\n' + 'państwowe np. Ministerstwo Sprawiedliwości, Ministerstwo Finansów, itp. Dane, które otrzymujesz\n' + 'odzwierciedlają dane zawarte w wybranych rejestrach i bazach publicznych oraz prywatnych. Do\n' + 'raportu dołączone są dokumenty z rejestrów urzędowych np. odpisy z Krajowego Rejestru Sądowego,\n' + 'Krajowego Rejestru Zadłużonych, itp.', isOpen: false },
    { title: 'Jaka jest aktualność otrzymanych danych?', answer: 'Informacje pozyskane za pomocą Serwisu są aktualne na godzinę 24:00 dnia poprzedniego.', isOpen: false },
    { title: 'Czy pozyskujecie dane osób zmarłych legalnie?', answer: 'Tak. Wszystkie dane są pozyskiwane legalnie.', isOpen: false },
    { title: 'Jak zacząć korzystać z mSpadek?', answer: 'Dostęp do Serwisu mSpadek jest możliwy bez rejestracji konta użytkownika. Po prostu zacznij\n' + 'korzystać z Serwisu zgodnie z jego Regulaminem link.', isOpen: false },
  ];

  toggle(item: any): void {
    this.questions.forEach(i => {
      if (i !== item) {
        i.isOpen = false;
      }
    });
    item.isOpen = !item.isOpen;
  }

  protected readonly open = open;
}
