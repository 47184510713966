<div id="main">
    <div class="inner">

      <div class="process-header">
        <h2>Zamówienie na</h2>
        <h1 *ngIf="productType==='PRIMARY'">Podstawowy raport spadkowy</h1>
        <h1 *ngIf="productType==='EXTENDED'">Rozszerzony raport spadkowy</h1>
      </div>

      <div class="process-content">
        <p class="process-content__highlight">Uzupłenij poniżej dane osoby zmarłej oraz osoby zamawiającej</p>

        <div class="process-content__form">
          <form class="process-content__form-grid" autocomplete="off" [formGroup]="orderForm" (submit)="onSubmit()" (change)="onFormChange()">


            <div class="process-content__form-section process-content__form-section-span">
                <section class="deceased col-lg-12">
                    <h3>Dane zmarłego</h3>
                </section>

              <div class="order-request__deceased-data">

                <div class="form-group">
                  <input class="form-control" type="text" id="name" formControlName="name" placeholder="name" autocomplete="off">
                  <label for="name" class="label">Imię zmarłego</label>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="surname" formControlName="surname" placeholder="surname" autocomplete="off">
                  <label for="surname" class="label">Nazwisko zmarłego</label>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="pesel" formControlName="pesel" placeholder="pesel" autocomplete="off">
                  <label for="pesel" class="label">PESEL zmarłego</label>
                  <span class="info">Upewnij się, że PESEL,
                    który podajesz jest prawidłowy inaczej nie otrzymasz poprawnego
                    raportu</span>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="nip" formControlName="nip" placeholder="nip" autocomplete="off">
                  <label for="nip" class="label">NIP zmarłego</label>
                </div>

                <div class="form-group">
                  <input class="form-control" type="date" id="dateOfDeath" formControlName="dateOfDeath" placeholder="dateOfDeath" autocomplete="off">
                  <label for="dateOfDeath" class="label">Data śmierci zmarłego</label>
                </div>
              </div>
            </div>

            <div class="process-content__form-section">

              <section class="deceased col-lg-12">
                <h3>Dane zamawiającego</h3>
              </section>

              <div class="form-group">
                <input class="form-control" type="text" id="clientName" formControlName="clientName" placeholder="clientName" autocomplete="off">
                <label for="clientName" class="label">Imię</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="clientSurname" formControlName="clientSurname" placeholder="clientSurname" autocomplete="off">
                <label for="clientSurname" class="label">Nazwisko</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="clientEmail" formControlName="clientEmail" placeholder="clientEmail" autocomplete="off">
                <label for="clientEmail" class="label">Adres e-mail</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="clientEmailConfirm" formControlName="clientEmailConfirm" placeholder="clientEmailConfirm" autocomplete="off">
                <label for="clientEmailConfirm" class="label">Powtórz adres-email</label>
              </div>

            </div>

            <div class="process-content__form-section">

              <section class="deceased col-lg-12">
                <h3>Dane do płatności</h3>
              </section>
              <div class="form-check">
                <label class="checkbox-label form-check-label checkbox-small" for="samePaymentData">
                  <input class="form-check-input" type="checkbox" (change)="onSamePaymentData($event)"  id="samePaymentData">
                  <span>
                    Te same dane do płatności
                  </span>

                </label>
            </div>

              <div class="form-group">
                <input class="form-control" type="text" id="payerName" formControlName="payerName" placeholder="payerName" autocomplete="off">
                <label for="payerName" class="label">Imię</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="payerSurname" formControlName="payerSurname" placeholder="payerSurname" autocomplete="off">
                <label for="payerSurname" class="label">Nazwisko</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="payerStreet" formControlName="payerStreet" placeholder="payerStreet" autocomplete="off">
                <label for="payerStreet" class="label">Adres (ulica, numer budynku/mieszkania)</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="payerZipCode" formControlName="payerZipCode" placeholder="payerZipCode" autocomplete="off">
                <label for="payerZipCode" class="label">Kod pocztowy (format XX-XXX)</label>
              </div>

              <div class="form-group">
                <input class="form-control" type="text" id="payerCity" formControlName="payerCity" placeholder="payerCity" autocomplete="off">
                <label for="payerCity" class="label">Miasto</label>
              </div>

            </div>

            <div class="process-content__form-section  process-content__form-section-span">
              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="acceptAll">
                    <input class="form-check-input" type="checkbox" formControlName="acceptAll" (change)="onAcceptAllChanged($event)" id="acceptAll">
                      Akceptuję wszystkie poniższe zgody i zaznaczam wszystkie oświadczenia.
                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="termsAndConditions">
                    <input class="form-check-input" type="checkbox" formControlName="termsAndConditions" id="termsAndConditions">
                    <span>
                      Oświadczam, że zapoznałem się i akceptuje Regulamin <a href="#" target="_blank">mSpadek.pl</a> oraz potwierdzam znajomość Polityki Prywatności.
                    </span>

                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="verificationOfTheDeceased">
                    <input class="form-check-input" type="checkbox" formControlName="verificationOfTheDeceased" id="verificationOfTheDeceased">
                    Oświadczam, że osoba weryfikowana nie żyje. Weryfikuję osobę zmarłą.
                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="consentToElectronicCommunications">
                    <input class="form-check-input" type="checkbox" formControlName="consentToElectronicCommunications" id="consentToElectronicCommunications">
                    Wyrażam zgodę na kontaktowanie się ze mną przez mSpadek.pl za pomocą środków komunikacji elektronicznej przy użyciu telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących, w tym poprzez e-mail, telefon lub SMS/MMS oraz komunikatory internetowe.
                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="marketingAndCommercialConsent">
                    <input class="form-check-input" type="checkbox" formControlName="marketingAndCommercialConsent" id="marketingAndCommercialConsent">
                    Wyrażam zgodę na otrzymywanie od mSpadek.pl za pomocą środków komunikacji elektronicznej na wskazany przeze mnie adres e-mail lub numer telefonu informacji handlowych i marketingowych dotyczących usług i produktów oferowanych przez mSpadek.pl.
                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="immediateExecution">
                    <input class="form-check-input" type="checkbox" formControlName="immediateExecution" id="immediateExecution">
                    Proszę o rozpoczęcie wykonywania usługi przed upływem terminu do odstąpienia od umowy zawartej na odległość i dostarczenie treści cyfrowych. Rozumiem, że oznacza to utratę prawa do odstąpienia od umowy, jeśli usługa została w całości wykonana lub udostępnianie treści cyfrowych rozpoczęło się i będę zobowiązany(a) do zapłaty za usługi świadczone na moją rzecz.
                  </label>
              </div>
          </div>

          <div class="process-content__form-section process-content__form-section-span">
            <div class="row action">
                <div class="col-lg-12 text-center" style="text-align: center">
                    <button [disabled]="orderForm.invalid" type="submit" class="primary cta">Zamów raport</button>
                </div>
            </div>
          </div>

        </form>

        <ng-template #confirmImmediateExecution let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Jesteś pewny?</h4>
    <!--            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>-->
            </div>
            <div class="modal-body">
                <p>Jeżeli nie wyrazisz zgody na na rozpoczęcie wykonywania usługi, <strong>raport dostarczymy dopiero za 14 dni</strong>. </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" (click)="c(true)">Jestem pewna/y</button>
                <button type="button" class="btn btn-primary" (click)="c(false)">Zaznaczę, co trzeba</button>
            </div>
          </ng-template>

      </div>
      </div>
    </div>
</div>



