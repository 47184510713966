import { Component } from '@angular/core';

@Component({
  selector: 'app-steps-two',
  standalone: true,
  imports: [],
  templateUrl: './steps-two.component.html',
  styleUrl: './steps-two.component.scss'
})
export class StepsTwoComponent {

}
