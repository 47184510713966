import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Order,
  OrderRequest,
  OrderResponse,
} from '../../../models/order.model';
import { ResponseSingle } from '../../../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public orderRequest: BehaviorSubject<OrderRequest | undefined> =
    new BehaviorSubject<OrderRequest | undefined>(undefined);
  public orderResponse: BehaviorSubject<
    ResponseSingle<OrderResponse> | undefined
  > = new BehaviorSubject<ResponseSingle<OrderResponse> | undefined>(undefined);
  private _apiUrl = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  public createOrder(
    payload: OrderRequest,
  ): Observable<ResponseSingle<OrderResponse>> {
    return this._http.post<ResponseSingle<OrderResponse>>(
      `${this._apiUrl}/orders`,
      payload,
    );
  }

  public updateOrder(
    orderId: string,
    payload: OrderRequest,
  ): Observable<ResponseSingle<OrderResponse>> {
    return this._http.put<ResponseSingle<OrderResponse>>(
      `${this._apiUrl}/orders/${orderId}`,
      payload,
    );
  }

  public getOrder(id: string): Observable<ResponseSingle<Order>> {
    return this._http.get<ResponseSingle<Order>>(
      `${this._apiUrl}/orders/${id}`,
    );
  }

  public getSexFromPesel(pesel: string): 'male' | 'female' {
    const sexNumber: number = Number(pesel.substring(9, 10));
    const isMale: boolean = sexNumber % 2 > 0;
    return isMale ? 'male' : 'female';
  }
}
