<section class="container">
    <main class="main">
        <article class="info">
            <h3>Ze statystyk wynika, że większość Polaków nie ma sporządzonego testamentu i nie jest świadoma konsekwencji jego braku, ani zasad dziedziczenia.</h3>
            <p>Ponieważ dodatkowo bardzo często dziedziczący nie utrzymują kontaktu z osobą zmarłą (spadkodawcą) to nagła konieczność podjęcia decyzji o przyjęciu lub odrzuceniu spadku staje się trudna. Wynika to z nieznajomości prawa, jak również z braku informacji odnośnie sytuacji osoby zmarłej, jej majątku lub możliwego zadłużenia. Spis Inwentarza chociaż to długotrwały i kosztowny proces bywa pewnym rozwiązaniem. Niemniej rozwiązania mSpadek są pomocne zarówno rodzinie jak i prawnikowi w podjęciu dobrej decyzji dotyczącej dziedziczenia.</p>
            <a class="cta cta--bold" routerLink="/order/primary">Zamów raport</a>
        </article>
        <div class="image">
            <img src="assets/images/sign@2x.png" alt="sign"/>
        </div>
    </main>
</section>
