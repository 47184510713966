import {Component, Input, OnInit,} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html' ,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

    @Input() showNavigation = false;

    public mobileMenuOpened: boolean = false;
    public mobileNoBrowserAddressBar: boolean = false;
    private _previousHeight = window.innerHeight;

    constructor() {
        this._previousHeight = window.innerHeight;
        window.addEventListener('resize', () => {
            this._onResize();
        })
    }

    ngOnInit() {
        this._onResize();
    }

   public toggleMenu() {
       this.mobileMenuOpened = !this.mobileMenuOpened
       if(!this.mobileMenuOpened) {
           this.mobileNoBrowserAddressBar = false;
       }
   }

   private _onResize() {
       const currentHeight = window.innerHeight;
       if (currentHeight < this._previousHeight ) {
           this.mobileNoBrowserAddressBar = false;
       } else if (currentHeight > this._previousHeight && this.mobileMenuOpened) {
           this.mobileNoBrowserAddressBar = true;
       }
       this._previousHeight = currentHeight;
   }
}
