<div id="main">
    <div class="inner">

      <div class="process-header">
        <h2>Zamówienie na</h2>
        <h1 *ngIf="productType==='PRIMARY'">Podstawowy raport spadkowy</h1>
        <h1 *ngIf="productType==='EXTENDED'">Rozszerzony raport spadkowy</h1>
      </div>

      <div class="process-content">
        <p class="process-content__highlight">Uzupłenij poniżej dane osoby zmarłej oraz osoby zamawiającej</p>

        <div class="process-content__form">
          <form class="process-content__form-grid" autocomplete="off" [formGroup]="orderForm" (submit)="onSubmit()" (change)="onFormChange()">


            <div class="process-content__form-section process-content__form-section-span">
                <section class="deceased col-lg-12">
                    <h3>Dane osoby zmarłej</h3>
                </section>

              <div class="order-request__deceased-data">

                <div class="form-group">
                  <input class="form-control" type="text" id="name" formControlName="name" placeholder="name" autocomplete="off">
                  <label for="name" class="label">Imię osoby zmarłej</label>
                    <div *ngIf="orderForm.get('name')?.invalid && orderForm.get('name')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('name')?.errors?.['required']">Imię jest wymagane.</span>
                        <span *ngIf="orderForm.get('name')?.errors?.['minlength']">Imię musi mieć co najmniej 3 znaki.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="surname" formControlName="surname" placeholder="surname" autocomplete="off">
                  <label for="surname" class="label">Nazwisko osoby zmarłej</label>
                    <div *ngIf="orderForm.get('surname')?.invalid && orderForm.get('surname')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('surname')?.errors?.['required']">Nazwisko jest wymagane.</span>
                        <span *ngIf="orderForm.get('surname')?.errors?.['minlength']">Nazwisko musi mieć co najmniej 3 znaki.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="pesel" formControlName="pesel" placeholder="pesel" autocomplete="off">
                  <label for="pesel" class="label">PESEL osoby zmarłej</label>
<!--                  <span  *ngIf="orderForm.get('pesel')?.value" class="form-group__error-warn">Upewnij się, że PESEL, który podajesz jest prawidłowy, inaczej nie otrzymasz poprawnego raportu</span>-->
                    <div *ngIf="orderForm.get('pesel')?.invalid && orderForm.get('pesel')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('pesel')?.errors?.['required']">PESEL jest wymagany.</span>
                        <span *ngIf="orderForm.get('pesel')?.errors?.['invalidPesel']">{{ orderForm.get('pesel')?.errors?.['invalidPesel'] }}</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="nip" formControlName="nip" placeholder="nip" autocomplete="off" #nipInput>
                  <label for="nip" class="label">NIP osoby zmarłej</label>
<!--                    <span *ngIf="!orderForm.get('nip')?.value" class="form-group__error-warn">NIP nie jest wymagany, ale jeżeli go nie podasz to niektore bazy danych zostaną pominięte w weryfikacji.</span>-->
                    <div *ngIf="orderForm.get('nip')?.invalid && orderForm.get('nip')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('nip')?.errors?.['invalidNip']">{{ orderForm.get('nip')?.errors?.['invalidNip'] }}</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="date" id="dateOfDeath" formControlName="dateOfDeath" placeholder="dateOfDeath" autocomplete="off" [max]="today">
                  <label for="dateOfDeath" class="label">Data śmierci</label>
                    <div *ngIf="orderForm.get('dateOfDeath')?.invalid && orderForm.get('dateOfDeath')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('dateOfDeath')?.errors?.['required']">Data śmierci jest wymagana.</span>
                    </div>
                </div>
              </div>
            </div>

            <div class="process-content__form-section process-content__form-section-span">

              <section class="deceased col-lg-12">
                <h3>Dane zamawiającego</h3>
              </section>

              <div class="order-request__deceased-data">

                <div class="form-group">
                  <input class="form-control" type="text" id="clientName" formControlName="clientName" placeholder="clientName" autocomplete="off">
                  <label for="clientName" class="label">Imię</label>
                    <div *ngIf="orderForm.get('clientName')?.invalid && orderForm.get('clientName')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('clientName')?.errors?.['required']">Imię jest wymagane.</span>
                        <span *ngIf="orderForm.get('clientName')?.errors?.['minlength']">Imię musi mieć co najmniej 3 znaki.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="clientSurname" formControlName="clientSurname" placeholder="clientSurname" autocomplete="off">
                  <label for="clientSurname" class="label">Nazwisko</label>
                    <div *ngIf="orderForm.get('clientSurname')?.invalid && orderForm.get('clientSurname')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('clientSurname')?.errors?.['required']">Nazwisko jest wymagane.</span>
                        <span *ngIf="orderForm.get('clientSurname')?.errors?.['minlength']">Nazwisko musi mieć co najmniej 3 znaki.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="clientEmail" formControlName="clientEmail" placeholder="clientEmail" autocomplete="off">
                  <label for="clientEmail" class="label">Adres e-mail</label>
                    <div *ngIf="orderForm.get('clientEmail')?.invalid && orderForm.get('clientEmail')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('clientEmail')?.errors?.['required']">Email jest wymagany.</span>
                        <span *ngIf="orderForm.get('clientEmail')?.errors?.['email']">Wprowadź poprawny adres e-mail.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="clientEmailConfirm" formControlName="clientEmailConfirm" placeholder="clientEmailConfirm" autocomplete="off">
                  <label for="clientEmailConfirm" class="label">Powtórz adres-email</label>
                    <div *ngIf="orderForm.get('clientEmailConfirm')?.invalid && orderForm.get('clientEmailConfirm')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('clientEmailConfirm')?.errors?.['required']">Email jest wymagany.</span>
                        <span *ngIf="orderForm.get('clientEmailConfirm')?.errors?.['email']">Wprowadź poprawny adres e-mail.</span>
                        <span *ngIf="orderForm.hasError('notMatch')">Adres e-mail nie pasuje.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="payerStreet" formControlName="payerStreet" placeholder="payerStreet" autocomplete="off">
                  <label for="payerStreet" class="label">Adres (ulica, numer budynku/mieszkania)</label>
                    <div *ngIf="orderForm.get('payerStreet')?.invalid && orderForm.get('payerStreet')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('payerStreet')?.errors?.['required']">Ulica jest wymagana.</span>
                        <span *ngIf="orderForm.get('payerStreet')?.errors?.['minlength']">Ulica musi mieć co najmniej 3 znaki.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="payerZipCode" formControlName="payerZipCode" placeholder="payerZipCode" autocomplete="off">
                  <label for="payerZipCode" class="label">Kod pocztowy (format XX-XXX)</label>
                    <div *ngIf="orderForm.get('payerZipCode')?.invalid && orderForm.get('payerZipCode')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('payerZipCode')?.errors?.['required']">Kod pocztowy jest wymagany.</span>
                        <span *ngIf="orderForm.get('payerZipCode')?.errors?.['pattern']">Kod pocztowy musi mieć forma XX-XXX.</span>
                    </div>
                </div>

                <div class="form-group">
                  <input class="form-control" type="text" id="payerCity" formControlName="payerCity" placeholder="payerCity" autocomplete="off">
                  <label for="payerCity" class="label">Miasto</label>
                    <div *ngIf="orderForm.get('payerCity')?.invalid && orderForm.get('payerCity')?.touched" class="form-group__error-message">
                        <span *ngIf="orderForm.get('payerCity')?.errors?.['required']">Miasto jest wymagane.</span>
                        <span *ngIf="orderForm.get('payerCity')?.errors?.['minlength']">Miasto musi mieć co najmniej 3 znaki.</span>
                    </div>
                </div>

              </div>

            </div>


            <div class="process-content__form-section  process-content__form-section-span">
              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small" for="acceptAll">
                    <input class="form-check-input" type="checkbox" formControlName="acceptAll" (change)="onAcceptAllChanged($event)" id="acceptAll">
                      Akceptuję wszystkie poniższe zgody i zaznaczam wszystkie oświadczenia.
                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small checkbox-subinfo" for="termsAndConditions"
                         [ngClass]="{'invalid-checkbox': orderForm.get('termsAndConditions')?.invalid && orderForm.get('termsAndConditions')?.touched}">
                    <input class="form-check-input" type="checkbox" formControlName="termsAndConditions" id="termsAndConditions">
                    <span>
                      Oświadczam, że zapoznałem się i akceptuję Regulamin oraz Politykę Prywatności.
                    </span>

                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small checkbox-subinfo" for="verificationOfTheDeceased"
                         [ngClass]="{'invalid-checkbox': orderForm.get('verificationOfTheDeceased')?.invalid && orderForm.get('verificationOfTheDeceased')?.touched}">
                    <input class="form-check-input" type="checkbox" formControlName="verificationOfTheDeceased" id="verificationOfTheDeceased">
                    Oświadczam, że osoba weryfikowana nie żyje. Weryfikuję osobę zmarłą.
                  </label>
              </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small checkbox-subinfo" for="consentToElectronicCommunications"
                         [ngClass]="{'invalid-checkbox': orderForm.get('consentToElectronicCommunications')?.invalid && orderForm.get('consentToElectronicCommunications')?.touched}">
                    <input class="form-check-input" type="checkbox" formControlName="consentToElectronicCommunications" id="consentToElectronicCommunications">
                    Wyrażam zgodę na kontaktowanie się ze mną przez mSpadek.pl poprzez e-mail, telefon lub SMS/MMS oraz komunikatory internetowe.*
                  </label>
              </div>

                <div class="form-check">
                    <label class="checkbox-label form-check-label checkbox-small checkbox-subinfo" for="immediateExecution"
                           [ngClass]="{'invalid-checkbox': orderForm.get('immediateExecution')?.invalid && orderForm.get('immediateExecution')?.touched}">
                        <input class="form-check-input" type="checkbox" formControlName="immediateExecution" id="immediateExecution">
                        Rozumiem, że opłacenie zamówienia spowoduje automatyczne wykonanie usługi cyfrowej i utracę prawo odstąpienia od umowy po rozpoczęciu jej realizacji.**
                    </label>
                </div>

              <div class="form-check">
                  <label class="checkbox-label form-check-label checkbox-small checkbox-subinfo" for="marketingAndCommercialConsent">
                    <input class="form-check-input" type="checkbox" formControlName="marketingAndCommercialConsent" id="marketingAndCommercialConsent">
                    Chcę otrzymywać od mSpadek.pl na mój e-mail lub numer telefonu informacje handlowe i marketingowe dotyczące usług i produktów mSpadek.pl
                  </label>
              </div>



              <div class="checkbox-info">
                  <p>* Wyrażenie zgody jest wymagane do korzystania z usługi. </p>
                  <p>** Opłacając zamówienie zgodzę się na rozpoczęcie świadczenia usługi cyfrowej przed upływem terminu odstąpienia od umowy.</p>
              </div>
          </div>

          <div class="process-content__form-section process-content__form-section-span">
            <div class="row action">
                <div class="col-lg-12 text-center" style="text-align: center">
                    <button type="submit" class="primary cta">Zamów raport</button>
                </div>
            </div>
          </div>

        </form>

<!--            <ng-template #confirmationNipModal let-modal>-->
<!--                <div class="modal-header">-->
<!--                    <h4 class="modal-title" id="modal-basic-title">Jesteś pewna/y?</h4>-->
<!--                    &lt;!&ndash;            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="modal-body">-->
<!--                    <p>W przypadku braku numeru <strong>NIP</strong> nie będzie możliwa weryfikacja niektórych baz i rejestrów.</p>-->
<!--                </div>-->
<!--                <div class="modal-footer">-->
<!--                    <button type="button" class="btn btn-outline-danger" (click)="onConfirm(true);modal.hide()">Tak, kontynuuj!</button>-->
<!--                    <button type="button" class="btn btn-primary" (click)="onConfirm(false);modal.hide()">Podaję NIP!</button>-->
<!--                </div>-->
<!--            </ng-template>-->


<!--        <ng-template #confirmationNipModal let-c="close" let-d="dismiss">-->
<!--            <div class="modal-header">-->
<!--                <h4 class="modal-title" id="modal-basic-title">Jesteś pewny?</h4>-->
<!--    &lt;!&ndash;            <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="modal-body">-->
<!--                <p>Jeżeli nie wyrazisz zgody na na rozpoczęcie wykonywania usługi, <strong>raport dostarczymy dopiero za 14 dni</strong>. </p>-->
<!--            </div>-->
<!--            <div class="modal-footer">-->
<!--                <button type="button" class="btn btn-outline-danger" (click)="c(true)">Jestem pewna/y</button>-->
<!--                <button type="button" class="btn btn-primary" (click)="c(false)">Zaznaczę, co trzeba</button>-->
<!--            </div>-->
<!--          </ng-template>-->

      </div>
      </div>
    </div>
</div>



