import { DeceasedReport, PrimaryReportResultStatus, ReportStatus } from '../models/report.model';

export const primaryReportExample:DeceasedReport = {
  status: ReportStatus.PROCESSED,
  type: 'PRIMARY',
  reportNumber: '2025/00000/PRZYKŁAD',
  updatedAt: new Date(),
  createdAt: new Date(),
  databaseStatusOn: new Date(),
  deceased: {
    name: 'Jan',
    lastName: 'Kowalski',
    peselNumber: '00000000000',
    dateOfDeath: new Date(),
    taxId: '000000000'
  },
  lookup: {
    ceidg: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    },
    crbr: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    },
    krz: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    },
    msig: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    },
    sudop: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    },
    vatWl: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    },
    krs: {
      status: ReportStatus.PROCESSED,
      timestamp: new Date(),
    }
  },
  result: {
    ceidg: PrimaryReportResultStatus.EXISTS,
    crbr: PrimaryReportResultStatus.EXISTS,
    krz: PrimaryReportResultStatus.NOT_EXISTS,
    sudop: PrimaryReportResultStatus.SKIPPED,
   msig: PrimaryReportResultStatus.NOT_EXISTS,
   vatWl: PrimaryReportResultStatus.SKIPPED,
   krs: {
      companyAuthorities: PrimaryReportResultStatus.EXISTS,
     propertyRights: PrimaryReportResultStatus.NOT_EXISTS
   }
  }

}
