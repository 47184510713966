import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-question-cta',
  standalone: true, imports: [RouterLink, RouterLinkActive],
  templateUrl: './question-cta.component.html',
  styleUrl: './question-cta.component.scss'
})
export class QuestionCtaComponent {

}
