import { Component, OnInit } from '@angular/core';
import { TermsService } from '../../services/terms.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public terms:any[] = []

  constructor(private _termService: TermsService) {}

  ngOnInit() {
    this._termService.getTerms().subscribe((terms:any) => {
      this.terms = terms.sections;
    })
  }
}

