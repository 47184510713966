import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function peselValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const pesel = control.value;

    if (!pesel) return null; // Puste pole = brak błędu (sprawdza to required)

    if (!/^\d{11}$/.test(pesel)) {
      return { invalidPesel: 'PESEL musi składać się z dokładnie 11 cyfr' };
    }

    // Wagi do sumy kontrolnej PESEL
    const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    let sum = 0;

    for (let i = 0; i < 10; i++) {
      sum += parseInt(pesel[i], 10) * weights[i];
    }

    const controlDigit = (10 - (sum % 10)) % 10;
    if (controlDigit !== parseInt(pesel[10], 10)) {
      return { invalidPesel: 'Niepoprawny numer PESEL (błąd sumy kontrolnej)' };
    }

    return null; // PESEL poprawny
  };
}
