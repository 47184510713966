<div id="main">
    <div class="inner">

      <div class="process-header">
        <h1>Potwierdzenie zamówienia</h1>
      </div>

      <div class="process-content">

        <div class="payment-status-box">

          <div>
            <p><strong>Numer zamówienia:</strong><span>{{ order?.orderNumber?.toUpperCase() }}</span></p>
            <p><strong>Data zamówienia:</strong><span>{{order?.details?.dateCreated | date: 'medium'}}</span></p>
          </div>
          <div>
            <p><strong>Status płatności: </strong>
              <span
                *ngIf="order?.payment?.status === 'COMPLETED'"
                class="badge badge-success">{{order?.payment?.status | paymentStatus}}</span>

              <span
                *ngIf="order?.payment?.status === 'FAILED'
                                || order?.payment?.status === 'CANCELED'"
                class="badge badge-warning">{{order?.payment?.status | paymentStatus}}</span>

              <span
                *ngIf="order?.payment?.status !== 'FAILED'
                                && order?.payment?.status !== 'CANCELED'
                                && order?.payment?.status !== 'COMPLETED'"
                class="badge badge-danger">{{order?.payment?.status | paymentStatus}}</span>
            </p>
            <p><strong>Id płatności:</strong><span>{{ order?.payment?.externalPaymentId}}</span></p>
          </div>

        </div>

        <main>
          <section>
            <table class="products">
              <thead>
              <tr>
                <th><h3 class="products__header products__header-item">Produkty</h3></th>
                <th><h3 class="products__header products__header-price">Cena</h3></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <div class="products__info">
                    <strong>Raport Podstawowy</strong>
                    <p>
                      {{order?.deceased?.name}} {{order?.deceased?.surname}}
                      | {{order?.deceased?.pesel}}
                    </p>
                  </div>

                </td>
                <td>
                  <div class="products__info-price"><strong>{{ 1 | currency}}</strong></div>
                </td>
              </tr>
              </tbody>
            </table>
          </section>

          <section class="delivery">
            <h2>Dane dostawy</h2>
            <p><strong>{{order?.client?.name}} {{order?.client?.surname}}</strong></p>
            <p>{{order?.client?.email}}</p>

          </section>

          <section class="report">
            <h2>Raport</h2>
            <p>Kiedy raport zostanie stworzony otrzymasz do niego link na wskazany adres email. Można go znaleźć również <strong class="report-link"> <a [routerLink]="['/report', orderId]">TUTAJ</a></strong>.</p>

          </section>

        </main>

      </div>

    </div>
</div>


