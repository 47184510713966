import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  private termsUrl = 'assets/jsons/terms-and-conditions.json';
  private privacyUrl = 'assets/jsons/privacy-policy.json';
  constructor(private http: HttpClient) { }

  getTerms(): Observable<any> {
    return this.http.get<any>(this.termsUrl);
  }

  getPrivacy(): Observable<any> {
    return this.http.get<any>(this.privacyUrl);
  }
}
