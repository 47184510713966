<section class="container">
    <div class="main">
        <header class="header">
            <h2>Opinie</h2>
            <p>
              Chcesz się dowiedzieć czy po kimś dziedziczysz? Potrzebujesz informacji, czy spadkodawca pozostawił
              jakieś aktywa lub długi np. w stosunku do Skarbu Państwa? Najpewniejszym, ale najdroższym i najbardziej
              czasochłonnym rozwiązaniem jest wykonanie Spisu Inwentarza. Nasze raporty spadkowe pomogą Ci w podjęciu
              decyzji.
            </p>
        </header>
        <main class="testimonials">

          <owl-carousel-o [options]="customOptions">

            @for (item of testimonials; track item) {
              <ng-template carouselSlide>
                <article class="testimonial">
                  <div class="testimonial__profile">
                    <img [src]="item.image" alt="profile">
                  </div>
                  <div class="testimonial__info">
                    <p class="testimonial__text">
                      {{ item.text }}
                    </p>
                    <p class="testimonial__full-name">
                      {{ item.name }}
                    </p>
                  </div>
                </article>
              </ng-template>
            }

          </owl-carousel-o>

        </main>
    </div>
</section>

