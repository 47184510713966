import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '../../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';



import { MatchValidator } from '../../../shared/validators/match.validator';
import {ProductType, ProductTypes} from '../../../../models/product.model';
import { OrderRequest, OrderResponse } from '../../../../models/order.model';
import { ResponseSingle } from '../../../../models/response.model';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.scss'],
})
export class OrderRequestComponent implements OnInit{
  @ViewChild('confirmImmediateExecution') confirmModal!:any
  public orderForm!: FormGroup;
  public acceptAll:boolean  = false;
  public productType!:ProductType;


  constructor(
      private _formBuilder:FormBuilder,
      private _orderServices:OrderService,
      private _router:Router,
      private _route: ActivatedRoute,
      private _cd:ChangeDetectorRef,

  ) {
  }

  ngOnInit() {

    this.productType = !!this._route.snapshot.url.find( item => item.path.includes('extended')) ? ProductTypes.EXTENDED : ProductTypes.PRIMARY;

    this._creatForm();
  }

  private _creatForm() {
    this.orderForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      surname: new FormControl('', [Validators.required, Validators.minLength(3)]),
      pesel: new FormControl('', [Validators.required, Validators.minLength(11)]),
      nip: new FormControl('', [Validators.required, Validators.minLength(10)]),
      dateOfDeath: new FormControl('', [Validators.required]),
      // fatherName: new FormControl('Janusz', [Validators.minLength(3)]),
      // motherName: new FormControl('Grazyna', [Validators.minLength(3)]),
      // motherMaidenName: new FormControl('Nowak', [Validators.minLength(3)]),
      clientName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      clientSurname: new FormControl('', [Validators.required, Validators.minLength(3)]),
      clientEmail: new FormControl('', [Validators.required, Validators.email]),
      clientEmailConfirm: new FormControl('', [Validators.required, Validators.email]),

      payerName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      payerSurname: new FormControl('', [Validators.required, Validators.minLength(3)]),
      payerStreet: new FormControl('', [Validators.required, Validators.minLength(3)]),
      payerZipCode: new FormControl('', [Validators.required, Validators.minLength(3)]),
      payerCity: new FormControl('', [Validators.required, Validators.minLength(3)]),


      acceptAll: new FormControl( false),
      termsAndConditions: new FormControl( false, [Validators.requiredTrue]),
      verificationOfTheDeceased: new FormControl( false, [Validators.requiredTrue]),
      consentToElectronicCommunications: new FormControl( false, [Validators.requiredTrue]),
      marketingAndCommercialConsent: new FormControl( false),
      immediateExecution: new FormControl( false)
    }, {
      validators: MatchValidator('clientEmail', 'clientEmailConfirm')
    });
  }

  private _makeOrder() {
    const order:OrderRequest =  {
      product: this.productType,
      client: {
        name: this.orderForm.getRawValue().clientName,
        surname: this.orderForm.getRawValue().clientSurname,
        email: this.orderForm.getRawValue().clientEmail
      },
      payer: {
        name: this.orderForm.getRawValue().payerName,
        surname: this.orderForm.getRawValue().payerSurname,
        street: this.orderForm.getRawValue().payerStreet,
        zipCode: this.orderForm.getRawValue().payerZipCode,
        city: this.orderForm.getRawValue().payerCity
      },
      deceased: {
        name:this.orderForm.getRawValue().name,
        surname: this.orderForm.getRawValue().surname,
        pesel: this.orderForm.getRawValue().pesel,
        nip: this.orderForm.getRawValue().nip,
        dateOfDeath: this.orderForm.getRawValue().dateOfDeath,
      },
      consent: {
        termsAndConditions: this.orderForm.getRawValue().termsAndConditions,
        verificationOfTheDeceased: this.orderForm.getRawValue().verificationOfTheDeceased,
        consentToElectronicCommunications: this.orderForm.getRawValue().consentToElectronicCommunications,
        marketingAgreement: this.orderForm.getRawValue().marketingAndCommercialConsent,
        immediateExecution: this.orderForm.getRawValue().immediateExecution,
      }



      // name: this.orderForm.getRawValue().name,
      // surname: this.orderForm.getRawValue().surname,
      // pesel: this.orderForm.getRawValue().pesel,
      // nip: this.orderForm.getRawValue().nip,
      // dateOfDeath: this.orderForm.getRawValue().dateOfDeath,
      // // fatherName: this.orderForm.getRawValue().fatherName,
      // // motherName: this.orderForm.getRawValue().motherName,
      // // motherMaidenName: this.orderForm.getRawValue().motherMaidenName,
      // clientName: this.orderForm.getRawValue().clientName,
      // clientSurname: this.orderForm.getRawValue().clientSurname,
      // clientEmail: this.orderForm.getRawValue().clientEmail,
      // termsAndConditions: this.orderForm.getRawValue().termsAndConditions,
      // verificationOfTheDeceased: this.orderForm.getRawValue().verificationOfTheDeceased,
      // consentToElectronicCommunications: this.orderForm.getRawValue().consentToElectronicCommunications,
      // marketingAndCommercialConsent: this.orderForm.getRawValue().marketingAndCommercialConsent,
      // immediateExecution: this.orderForm.getRawValue().immediateExecution,

    };

    this._orderServices.orderRequest.next(order);
    this._orderServices.createOrder(order).subscribe( (data:ResponseSingle<OrderResponse>) => {
      this._orderServices.orderResponse.next(data);

      this._router.navigate(['order','preview',data.data.token])
    });
  }

  public onSubmit() {
      // if(!this.orderForm.getRawValue().immediateExecution) {
      //   console.log('Are you sure?')
      //  const modalRef:NgbModalRef =  this._modalService.open(this.confirmModal);
      //   this._cd.detectChanges();
      //   modalRef.closed.subscribe( data => {
      //     if(data) {
      //       this._makeOrder();
      //     }
      //   })

      // } else {
        this._makeOrder();
      // }



  }

  public onAcceptAllChanged(event:any) {
      this.orderForm.patchValue({
        termsAndConditions: event.target.checked,
        verificationOfTheDeceased: event.target.checked,
        consentToElectronicCommunications: event.target.checked,
        marketingAndCommercialConsent: event.target.checked,
        immediateExecution: event.target.checked
      })
    this._cd.detectChanges();
  }

  public onFormChange() {
    if(
        this.orderForm.getRawValue().termsAndConditions &&
        this.orderForm.getRawValue().verificationOfTheDeceased &&
        this.orderForm.getRawValue().consentToElectronicCommunications &&
        this.orderForm.getRawValue().marketingAndCommercialConsent &&
        this.orderForm.getRawValue().immediateExecution
    ) {
      this.orderForm.patchValue({
        acceptAll: true
      })
      this._cd.detectChanges();
    } else {
      this.orderForm.patchValue({
        acceptAll: false
      })
      this._cd.detectChanges();
    }
  }





}
