import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ContactMessageRequest,
  ContactMessageResponse,
} from 'src/app/models/contact-message.model';
import { ResponseSingle } from 'src/app/models/response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private _apiUrl = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  public sendMessage(
    payload: ContactMessageRequest,
  ): Observable<ResponseSingle<ContactMessageResponse>> {
    return this._http.post<ResponseSingle<ContactMessageResponse>>(
      `${this._apiUrl}/contact/message`,
      payload,
    );
  }
}
