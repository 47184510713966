import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ProductType, ProductTypes } from 'src/app/models/product.model';
import { ResponseSingle } from 'src/app/models/response.model';
import { Order, OrderResponse } from '../../../../models/order.model';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.scss'],
})
export class OrderPreviewComponent implements OnInit, OnDestroy {
  public order!: Order;
  public paymentRedirectUri?: string;
  protected readonly window = window;
  public orderEditUrl: string = '';
  public productType!: ProductType;

  constructor(
    private _orderService: OrderService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {}

  ngOnInit() {
    this.productType = !!this._route.snapshot.url.find((item) =>
      item.path.includes('extended'),
    )
      ? ProductTypes.EXTENDED
      : ProductTypes.PRIMARY;

    const orderId = this._route.snapshot.params['id'];
    if (orderId) {
      this._orderService.getOrder(orderId).subscribe((data) => {
        this.order = data.data;
        this.orderEditUrl = `/order/${data.data.details.product.toLowerCase()}/${orderId}`;
      });
      this.paymentRedirectUri =
        this._orderService.orderResponse.getValue()?.data.redirectUri;
    } else {
      const currentState = this._router.lastSuccessfulNavigation;
      this.order = currentState?.extras?.state?.['data'];
      this.orderEditUrl = `/order/edit/${this.productType.toLowerCase()}`;
    }
  }

  public onSubmit() {
    const order = {
      ...this.order,
      product: this.productType,
    };
    this._orderService.orderRequest.next(order);
    this._orderService
      .createOrder(order)
      .subscribe((data: ResponseSingle<OrderResponse>) => {
        this._orderService.orderResponse.next(data);
        this.paymentRedirectUri =
          this._orderService.orderResponse.getValue()?.data.redirectUri;
        this.goToUrl(this.paymentRedirectUri);
      });
  }

  public goToUrl(url: string | undefined): void {
    if (!url) {
      return;
    }
    this.window.open(url, '_self');
  }

  public navigate(url: string): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: this.order,
      },
    };

    this._router.navigate([url], navigationExtras);
  }

  ngOnDestroy() {
    // this._orderService.orderRequest.unsubscribe();
  }
}
