import { Component } from '@angular/core';

@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrl: './institutions.component.scss',
})
export class InstitutionsComponent {
  public institutions = [
    {
      name: 'Ministerstwo Sprawiedliwości',
      description:
        'Udostępnia nam informacje z baz danych używanych do przygotowania raportu np. KRS, KRZ i MSiG',
    },
    {
      name: 'Ministerstwo Rozwoju i Technologii',
      description:
        'Udziela nam informacji o działalności gospodarczej osób fizycznych',
    },
    {
      name: 'Ministerstwo Finansów',
      description:
        'Udostępnia nam dane z Centralnego Rejestru Beneficjentów Rzeczywistych',
    },
    {
      name: 'Urząd Ochrony Danych Osobowych',
      description: 'Nadzoruje sposób w jaki zarządzamy danymi osobowymi',
    },
  ];
}
