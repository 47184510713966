<section class="container">
    <main class="main">
        <div class="image">
            <img *ngIf="isMobile" src="assets/images/devices.jpg" alt="devices with mspdaek logo"/>
        </div>
        <div class="list">
            <ol>
                <li>Chcesz się dowiedzieć kto po tobie dziedziczy?</li>
                <li>Nie wiesz w jakich częściach dziedziczą twoi spadkobiercy?</li>
                <li>Zrób szybki test i uzyskaj odpowiedz.</li>
                <li>Szybko<br>i całkowicie za darmo.</li>
            </ol>
        </div>
    </main>

</section>
