<div class="institutions-container">
  <h2 class="institutions-title">
    Instytucje dbające o dane i informacje, które otrzymujesz w raporcie o osobie zmarłej
  </h2>
  <div class="institutions-list">
    <div *ngFor="let institution of institutions" class="institution-card">
      <h3 class="institution-title">{{ institution.name }}</h3>
      <p class="institution-description">{{ institution.description }}</p>
    </div>
  </div>
</div>
