<section class="container">
    <main class="main">
        <h2>5 głównych kroków,<br>które wykonamy dla Ciebie:</h2>
        <ol>
            <li>Poznasz sytuację osoby zmarłej w bazach i rejestrach (np. majątek i długi)</li>
            <li>Szybko otrzymasz informacje. Oszczędzisz czas</li>
            <li>Sprawniej załatwisz formalności spadkowe np. w sądzie i urzędzie skarbowym</li>
            <li>Łatwiej zabezpieczysz lub odzyskasz majątek spadkowy</li>
            <li>Korzystniej podzielisz spadek, jeśli dziedziczysz go z innymi osobami i nie jesteś jedynym spadkobiercą</li>
        </ol>
    </main>
</section>
