<header class="header print-header">
    <div class="container">

        <div class="container__header">
            <div>
                <a routerLink="/" class="header__logo">
                    <span class="symbol"><img src="/assets/images/logo.svg" alt="mSpadek"/></span>
                </a>
            </div>
            <div *ngIf="showNavigation">
                <div class="hamburger" (click)="toggleMenu()" [class.hamburger--opened]="mobileMenuOpened">
                    <span class="hamburger__top-bun"></span>
                    <span class="hamburger__bottom-bun"></span>
                </div>
            </div>
        </div>

        <div *ngIf="showNavigation" class="container__footer" [class.container__footer--no-browser-bar]="mobileNoBrowserAddressBar" [class.container__footer--opened]="mobileMenuOpened"   >
            <div>
                <ul class="navigation__list f_montserrat-normal" [class.navigation__list--opened]="mobileMenuOpened">
                    <li class="navigation__list-item"> <a routerLink="/" fragment="products" [ngxScrollToOffset]="-200" [ngxScrollTo]="'#products'">raporty</a></li>
                    <li class="navigation__list-item"><a routerLink="/terms-and-conditions" routerLinkActive="active">regulamin</a></li>
                    <li class="navigation__list-item"><a routerLink="/" routerLinkActive="active" [ngxScrollToOffset]="-120" [ngxScrollTo]="'#contact'">kontakt</a></li>
                    <li class="navigation__list-item navigation__list-item--cta">
                      <a class="cta cta--bold" [ngxScrollToOffset]="-200" [ngxScrollTo]="'#products'">Zamów raport</a>
                    </li>
                </ul>
            </div>
            <div class="social" [class.social--opened]="mobileMenuOpened">
                <div class="social__logo">
                    <img src="assets/images/logo_white.svg" alt="mSpadek logo">
                </div>
                <div>
                    <ul class="social__list">
                        <li class="social__item"><a><img src="assets/images/facebook_blue.svg" alt="Facebook logo"></a></li>
                        <li class="social__item"><a><img src="assets/images/x_blue.svg" alt="X logo"></a></li>
                        <li class="social__item"><a><img src="assets/images/email_blue.svg" alt="Email icon"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
