<div class="container" id="products">
    <main class="products" >

        <article class="product">
            <header class="product__header">
                <div>
                    <h2>Kto dziedziczy</h2>
                </div>
                <div class="price">
                    <div>0 zł</div>
                    <div class="price__details">test</div>
                </div>
            </header>

            <main class="product__details">
                <h3>Szybko sprawdzisz:</h3>
                <ul>
                    <li>kto jest spadkobiercą</li>
                    <li>tylko w 2 minuty</li>
                    <li>bez rejestracji</li>
                    <li>zupełnie za darmo</li>
                </ul>
            </main>
            <div class="spacer"></div>
            <footer class="product__footer">
                <a class="cta cta--100-w" href="https://ktopokim.pl" target="_blank">Zrób test</a>
            </footer>

        </article>


        <article class="product">
            <header class="product__header">
                <div>
                    <h2>Podstawowy</h2>
                </div>
                <div class="price">
                    <div>1 zł</div>
                    <div class="price__details">raport</div>
                </div>
            </header>

            <main class="product__details">
                <h3>Szybko sprawdzisz, czy osoba zmarła:</h3>
                <ul>
                    <li>występuje w wybranych publicznych bazach danych</li>
                    <li>występuje w wybranych prywatnych bazach danych<span class="asterisk">*</span></li>
                    <li>pozostawiła jakieś potencjalne aktywa lub długi</li>
                    <li>W raporcie otrzymasz opis znaczenia baz danych</li>
                </ul>
            </main>
            <div class="spacer"></div>
            <footer class="product__footer">
                <div class="report-btn">
                    <a class="report-btn__link" routerLink="/report/primary">Zobacz przykładowy raport</a>
                </div>
                <a class="cta cta--100-w" routerLink="/order/primary">Zamów raport</a>
            </footer>
        </article>


        <article class="product">
            <header class="product__header">
                <div>
                    <h2>Rozszerzony</h2>
                </div>
                <div class="price">
                    <div>49 zł</div>
                    <div class="price__details">raport</div>
                </div>
            </header>
            <main class="product__details">
                <h3>Szybko sprawdzisz, czy osoba zmarła:</h3>
                <ul>
                    <li>występuje w wybranych publicznych i prywatnych bazach danych<span class="asterisk">*</span></li>
                    <li>pozostawiła jakieś potencjalne aktywa lub długi</li>
                </ul>
                <br/>
                <h3>W raporcie otrzymasz:</h3>
                <ul>
                    <li>szczegółowe informacje o osobie zmarłej z wybranych baz danych</li>
                    <li>szczegółowe dane o potencjalnych aktywach lub długach</li>
                    <li>oficjalne dokumenty z wybranych baz danych</li>
                    <li>dokument zredagowany w jasnej i przystępnej formie</li>
                </ul>

            </main>
            <div class="spacer"></div>
            <footer class="product__footer">
                <div class="report-btn">
                    <a class="report-btn__link" routerLink="/report/extended">Zobacz przykładowy raport</a>
                </div>

                <a class="cta cta--100-w cta--disabled" routerLink="/order/extended">Zamów raport</a>
            </footer>
        </article>


    </main>
</div>
