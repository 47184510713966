import { Component, OnInit } from '@angular/core';
import { CoreModule } from '../../core.module';
import { NgForOf, NgIf } from '@angular/common';
import { TermsService } from '../../services/terms.service';

@Component({
  selector: 'app-privacy',
  standalone: true, imports: [CoreModule, NgForOf, NgIf],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss'
})
export class PrivacyComponent  implements OnInit {

  public privacy:any[] = []

  constructor(private _termService: TermsService) {}

  ngOnInit() {
    this._termService.getPrivacy().subscribe((privacy:any) => {
      this.privacy = privacy.sections;
    })
  }
}
