import { ProductType } from './product.model';
import { ReportStatus } from './report.model';


export interface Person {
    name: string;
    lastName: string;
}

export interface Client extends Person {
    email: string;
}

export interface Payer extends Person {
    street:string;
    postalCode: string;
    city: string;
    email: string;
}

export interface Deceased extends Person {
    peselNumber: string;
    taxId: string;
    dateOfDeath: Date;
}


export interface Consent {
    termsAndConditions:boolean;
    verificationOfTheDeceased:boolean;
    consentToElectronicCommunications:boolean;
    marketingAgreement:boolean;
    immediateExecution:boolean;
}


export interface OrderRequest {
    product:ProductType;
    client:Client;
    payer: Payer;
    deceased:Deceased;
    consent:Consent;
}

export interface OrderResponse {
    paymentGateway: string;
    paymentId: string;
    redirectUri: string;
    token: string;
}

export interface Order {
    client: Client;
    payer: Payer,
    deceased: Deceased;
    consent: Consent;
    details: {
        dateCreated: Date;
        dateUpdated: Date;
        id: string;
        paymentGateway: string;
        paymentId: string;
        product: ProductType;

    },
    payment: {
        dateCreated: Date;
        dateUpdated: Date;
        externalPaymentId: string;
        status: PaymentStatus
    }
    orderNumber:string;
    report: {
        token: string;
        status: ReportStatus;
    }


}


export type PaymentStatus =  'CREATED' | 'SUBMITTED' | 'COMPLETED' | 'FAILED' | 'CANCELED';
export enum PaymentStatuses {
    CREATED ='CREATED',
    SUBMITTED ='SUBMITTED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED'
}


