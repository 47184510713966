<section class="container">
    <main class="main">
        <header>
            <h2>Często zadawane pytania</h2>
        </header>
        <div>
            <article class="accordion" *ngFor="let question of questions">
                <header class="accordion__header" (click)="toggle(question)">
                    <h3 class="accordion__title">{{ question.title }}</h3>
                    <div class="accordion__button" [class.accordion__button--opened]="question.isOpen">
                        <div></div>
                    </div>
                </header>
                <main class="accordion__body" [class.accordion__body--opened]="question.isOpen">
                    <div>
                        <p>{{ question.answer }}<span *ngIf="question.asterisk" class="asterisk">*</span></p>
                    </div>
                </main>
            </article>
        </div>

    </main>

</section>
