import { Component } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {CarouselModule, OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonials',
  standalone: true, imports: [CommonModule, NgOptimizedImage, CarouselModule],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.scss'
})
export class TestimonialsComponent {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      1060: {
        items: 2
      },
      1320: {
        items: 3
      }
    },
    nav: false
  }

  testimonials = [
    {
      image: 'assets/images/profile_1@2x.png',
      text: 'Raport o moim zmarłym bracie pokazał zaskakujące informacje i pomógł mi w podziale spadku.',
      name: 'JAN KOWALSKI',
    },
    {
      image: 'assets/images/profile_2@2x.png',
      text: 'Jestem zadowolona, że mogłam sprawdzić zmarłego męża w różnych rejestrach. Szybko i w rozsądnej cenie.',
      name: 'ALICJA KOWALSKA',
    },
    {
      image: 'assets/images/profile_3@2x.png',
      text: 'Tego potrzebowaliśmy. Ojciec nie był zbyt wylewny w sprawach finansowych, więc raport pomógł nam zorientować się w jakich rejestrach ojciec występował.',
      name: 'MARCIN KOWALSKI',
    },
    {
      image: 'assets/images/profile_2@2x.png',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus nec nunc.',
      name: 'JOHN DOE'
    },
    {
      image: 'assets/images/profile_3@2x.png',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus nec nunc.',
      name: 'JANE DOE'
    },

  ];
}
