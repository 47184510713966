<section class="container">
    <main class="main">
        <div class="header">
            <h2>Wszystko jest podane w prostej i przystępnej formie</h2>
        </div>

        <article class="advantage span-row-2 ">
            <ul>
                <li><span>Opisujemy jakie konsekwencje ma dla Ciebie <strong>występowanie osoby zmarłej w rejestrach i bazach danych</strong>.</span></li>
                <li><span><strong>Wszystko za jednym kliknięciem</strong>. Bez konieczności ręcznego przeszukiwania rejestrów i baz.</span></li>
                <li><span>Nie musisz wypełniać wielu wniosków, ani <strong>tracić czasu</strong> na przeszukiwanie internetu.</span></li>
            </ul>
        </article>

        <article class="advantage span-row-2 ">
            <ul>
                <li><span>Bez prawniczego bełkotu, branżowej i urzędowej nowomowy. Bez uwag pisanych małą czcionką. <strong>Wszystko jest zrozumiale opisane</strong>.</span></li>
                <li><span><strong>W ciągu kilku minut</strong> otrzymasz dokument.</span></li>
                <li><span><strong>Doskonała pomoc</strong> do konsultacji z prawnikiem w sprawach spadkowych.</span></li>
            </ul>
        </article>

        <div class="cta-button">
            <a class="cta cta--bold" routerLink="/order/primary" routerLinkActive="active">Zamów raport</a>
        </div>
    </main>
    <div class="logo">
        <img src="assets/images/logo.svg" alt="mspadek logo"/>
    </div>
</section>
