import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {
  constructor(private modalService: BsModalService) {}

  confirm({
    title,
    message,
    confirm,
    cancel,
  }: {
    title: string;
    message: string;
    confirm?: string;
    cancel?: string;
  }): Observable<boolean> {
    const result = new Subject<boolean>();

    this.modalService.show(ConfirmationModalComponent, {
      initialState: { title, message, confirm, cancel, result },
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
    });

    return result.asObservable();
  }
}
