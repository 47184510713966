export function getDateDaysAgo(
  date: Date | undefined,
  days: number,
): Date | null {
  if (!date || !days) {
    return null;
  }
  date = new Date(date);
  const pastDate: Date = new Date(date);
  pastDate.setDate(date.getDate() - days);
  return pastDate;
}
export function getDateString(date: Date): string {
  date = new Date(date);
  return `${date.getFullYear()}-${String('0' + date.getMonth()).slice(-2)}-${String('0' + date.getDate()).slice(-2)}`;
}
